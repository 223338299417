

.search-container .single-job-row:hover {
    background: rgb(205, 237, 255);
}



.search-container .single-job-row {
    /* cursor: pointer; */
    border:solid 1px rgb(230, 230, 230);
    border-radius: 7px;
    /* padding: 20px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 12px 84px -41px #92bffb;
    height: 160px;
    text-decoration: none;
}

.search-container .single-job-row.feature {
    background: rgba(158, 255, 119, 0.466);
}

.search-container .single-job-row .col1 {
    background: white;
    border-radius: 9px;
    width: 150px;
    height: 150px;
    margin: 32px 0 0 20px;
    box-shadow: 10px 10px 43px -20px #4f504f;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.search-container .single-job-row .col2 {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    max-width: 300px;
    /* margin-left: -117px; */
    align-items: flex-start;
    /* border: solid 1px; */
    height: 145px;
}


.search-container .single-job-row .col2 .title:hover,  
.search-container .single-job-row .col2 .title:visited,  
.search-container .single-job-row .col2 .title:active,
.search-container .single-job-row .col2 .title:focus {
    color:rgb(27 201 170);
}

.search-container .single-job-row .col2 .title {
    font-size: 20px;
    font-weight: 700;
    color: rgb(27 201 170);
    /* text-decoration: underline; */
}

.search-container .single-job-row .col2 .location {
    font-size: 14px;
    color: #a1a0a0;
}

.search-container .single-job-row .col2 .company-name {
    font-size: 16px;
    color: #0808c9;
    font-weight: 600;
}


.search-container .single-job-row .col3 {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    flex-direction: column;
    gap: 12px;
    padding-right: 7px;
    width: 200px;
    /* border: solid 1px; */
    height: 145px;
}

.search-container .single-job-row .col3 .employment-type {
    font-size: 14px;
    background: #2DB346;
    color: white;
    border-radius: 3px;
    padding: 11px;

    /* this means that the content will not wrap at all even if it causes overflows. */
    width: max-content;   
    /* min-width: 150px; */
}

.search-container .single-job-row .col3 .unknown-val {
    color : #c1c1c1;
    font-style: italic;
}

.search-container .single-job-row .col3 .salary {
    font-size: 14px;
    font-weight: 500;
    width: 170px;
    width: fit-content;
    /* direction: rtl; */
    text-align: right;
}

.search-container .single-job-row .col3 .postingdate.blue-date {
    font-size: 16px;
    color: #1f1fb9;
    font-weight: 500;
}

.search-container .single-job-row .col3 .postingdate.red-date {
    font-size: 16px;
    color: #ff0000;
    font-weight: 500;
}



.search-container .single-job-row .company-logo {
    border-radius: 5px;
    width: 150px;
    height: 150px;
}


.search-container .single-job-row a {
    /* text-decoration: none; */
    color: rgb(27 201 170);
}





@media (max-width: 500px) {

    .search-container .single-job-row {
        /* cursor: pointer; */
        border: solid 1px rgb(230, 230, 230);
        border-radius: 7px;
        /* padding: 20px; */
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 25px;
        width: 100%;
        border-radius: 5px;
        box-shadow: 0 12px 84px -41px #92bffb;
        height: 135px;
        text-decoration: none;
        padding-left: 5px;
    }
    
    .search-container .single-job-row .col1 {
        display: none;
    }

    .search-container .single-job-row .col2,
    .search-container .single-job-row .col3 {
        gap: 2px;
        width: 100%;
        height: 100px;
    }

    .search-container .single-job-row .col2 .title {
        font-size: 13px !important;
        font-weight: 700;
        color: rgb(27 201 170);
    }

    .search-container .single-job-row .col2 .location {
        font-size: 11px;
        color: #a1a0a0;
    }

    .search-container .single-job-row .col2 .company-name {
        font-size: 11px;
        color: #0808c9;
        font-weight: 600;
    }

    .search-container .single-job-row .col3 .employment-type {
        font-size: 11px;
        background: #2DB346;
        color: white;
        border-radius: 3px;
        padding: 8px;
        /* min-width: 120px; */

        /* this means that the content will not wrap at all even if it causes overflows. */
        width: max-content;   
        /* min-width: 150px; */
    }

    .search-container .single-job-row .col3 .salary {
        font-size: 11px;
        font-weight: 500;
        width: 170px;
        width: -moz-fit-content;
        width: fit-content;
        /* direction: rtl; */
        text-align: right;
    }

    .search-container .single-job-row .col3 .postingdate.blue-date {
        font-size: 16px;
        color: #1f1fb9;
        font-weight: 500;
    }
    
    .search-container .single-job-row .col3 .postingdate.red-date {
        font-size: 16px;
        color: #ff0000;
        font-weight: 500;
    }

}






