

.notfound-container .blue-div {
    width: 100%;
    height: 300px;
    color: white;
    position: relative;
    background: #232ca7d6;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 38px;
    font-weight: 500;
    padding-left: 110px;
}

.notfound-container .home-404error {
    background: #1967D2;
    border-radius: 10px;
    color: white;
    position: relative;
    left: 43%;
    height: 30px;
    width: 180px;
    top: -26px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 191px;
    height: 51px;
    font-weight: 600;
    font-size: 16px;
}

.notfound-container .page-content {
    /* border: solid 1px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;

}

.notfound-container .number-404 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 167px;
    font-family: serif, sans-serif;
}

.notfound-container .number-404 .right-4
.notfound-container .number-404 .left-4 {
    color: #183f60;
}

.notfound-container .zero {
    z-index: 3;
    margin-left: -22px;
    color: #0b47a5;
}

.notfound-container .right-4 {
    z-index: 6;
    margin-left: -18px;
}


.notfound-container .small-horiz-line hr {    
    border: solid 1px #cbc3ed;
    width: 100px;
    margin-top: -21px;
}

.notfound-container .bottom-text {
    font-size: 30px;
    margin-top: -44px;
}


.notfound-container .page-content .home-page-link {
    color: white;
    font-size: 19px;
    border-radius: 7px;
    background: #0b47a5;
    margin-top: 25px;
}


.notfound-container .home-page-link a,
.notfound-container .home-page-link a:hover  
.notfound-container .home-page-link a:visited  
.notfound-container .home-page-link a:active  
.notfound-container .home-page-link a:focus { 
    text-decoration: none;
    color: #f5f5f5;
    background: #382aa5;
    padding: 18px;
    font-size: 20px;
    border-radius: 7px;
}