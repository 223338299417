


div.card {
    border-radius: 7px;
    background-image: linear-gradient(to right, rgb(13, 185, 221) , rgb(39, 240, 158));
    height: 110px;
    width: 250px; 
    margin: 40px;       
}

div.left-side {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

div.card div.left-side div.prim-val {
    padding: 20px;
    font-size: 2em;
}

div.card div.left-side div.val-desc {
    padding: 16px;
    font-size: 1em;
}

.font-color {
    color: aliceblue;
    border: solid 1px black;
}