
.job-post-container .page-container {   
    background: rgb(236, 242, 248);
    padding: 30px;
}

.job-post-container .title-section {
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    position: relative;
    padding: 15px 30px;
    margin-bottom: 30px;
    box-shadow: 0 20px 30px rgba(0, 51, 90, .05);
    border-radius: 10px;
}

.job-post-container .fields-section {
    padding: 30px 30px 0 30px;
    background: white;
    border-radius: 10px;
}

.job-post-container .fields-section .row-wrap {
    display: flex;
    flex-wrap: wrap;
}

.job-post-container .page-container .title-section .title h2{
    font-size: 25px;
    color: #17171d;
    font-weight: 600;
    margin-bottom: 11px;
    font-family: Rubik, sans-serif, arial;
}

.job-post-container .page-container .title-section .path {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 11px;
}

.job-post-container .page-container .title-section .path .circle {
    border-radius: 50%;
    width: 5px;
    height: 5px;
    background: #17171d;
    margin-top: 4px;
}

.job-post-container .page-container .title-section .path .change-password {
    color: rgb(88, 88, 255);
}

.job-post-container .btn-save-profile {
    flex: 0 0 auto;
    width: 100%;
    /* direction: rtl; */
    text-align: right;
}

.job-post-container .fields-section .row-wrap .field-comp {
    margin-bottom: 40px;
}

.job-post-container .fields-section .row-wrap .pay-info-and-post {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.job-post-container .fields-section .row-wrap .pay-info-and-post .pay-info {
    font-size: 14px;
    font-style: italic;
    /* color: #c1c1c1; */
    
    /* new design */
    color: #7a7a7a;
    border: solid 1px;
    padding: 9px 25px 9px 7px;
    border-radius: 5px;
    background: #fff1dc;
}

.job-post-container .fields-section .row-wrap .pay-info-and-post .pay-info-mobile {
    display: none;
}

.job-post-container #div-apply {
    height: 50px;
    min-height: 50px;
}

.job-post-container #div-apply_2 {
    height: 50px;
    min-height: 50px;
    margin-left: auto;
}

.job-post-container .job-btn-itself {
    text-shadow: 0 0 0 #fff, 400px 0 0 #fff;
    cursor: pointer;
    color: white;
    padding: 15px 30px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    background-color: #1967d2;
    border: none;
    font-size: 16px;
    width: 120px;
    min-width: 120px;
}

.job-post-container .fields-section .row-wrap .disabled {
    background-color: #d5d5d5;
    color: #9d9d9d;
    /* text-shadow: 0 0 0 #fff, 400px 0 0 #fff; */
    cursor: not-allowed;
    /* padding: 15px 30px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    border: none;
    font-size: 16px;
    width: 120px;
    min-width: 120px;
    text-align: center;
    margin-left: auto; */
}

.job-post-container .fields-section .row-wrap .country-section ,
.job-post-container .fields-section .row-wrap .category-section {
    flex: 0 0 auto;
    width: 50%;
}

.job-post-container .fields-section .row-wrap .employmenttpye-section ,
.job-post-container .fields-section .row-wrap .workplace-section ,
.job-post-container .fields-section .row-wrap .gender-section {
    flex: 0 0 auto;
    width: 33.33%;
}

.job-post-container .fields-section .row-wrap .company-emailsubject-section {
    flex: 0 0 auto;
    width: 100%;
}

.job-post-container .fields-section .row-wrap .job-title-section ,
.job-post-container .fields-section .row-wrap .salary-title-section {
    flex: 0 0 auto;
    width: 50%;
}

.job-post-container .fields-section .row-wrap .job-description-section {
    flex: 0 0 auto;
    width: 100%;
}

.job-post-container .fields-section .row-wrap .comp-title {
    margin-bottom: 11px;
    color: #17171d;
    font-weight: 500;
}

.job-post-container .fields-section .row-wrap .input-itself:focus {
    border: solid 1px #8ab5f5;
    outline: none;
}

.job-post-container .fields-section .row-wrap .input-itself {
    padding: 15px 20px;
    height: 55px;
    /* padding: 15px 20px; */
    border: solid 1px #cfdbed;
    border-radius: 10px;
    box-shadow: 0 7px 15px rgba(61, 70, 83, .1);
    background-color: #fff;
    display: block;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
}



/* .job-post-container .ql-container {
    min-height: 150px !important;
    height: 150px !important;

    width: 430px !important;
}

.job-post-container .ql-editor {
    max-height: 250px !important;
} */


.job-post-container  .fields-section .row-wrap .ql-container {
    min-height: 250px !important;
    height:250px !important;
    width: 100% !important;
}

.job-post-container .fields-section .row-wrap .ql-toolbar.ql-snow {
    width: 100%;
}

.job-post-container .fields-section .row-wrap .ql-editor {
    max-height: 250px !important;
}




.job-post-container .fields-section .row-wrap .field-comp-pr {
    padding-right: 25px;
}

.job-post-container .fields-section .row-wrap .field-comp-pl {
    padding-left: 25px;
}






/* my mobile =  Resolution, width: 360, height: 512 pixels      
from https://www.google.com/search?q=samsung+galaxy+a02+core+dimensions+in+px&sca_esv=5e5568ccfe61287d&sca_upv=1&sxsrf=ADLYWII6uHR2k1JHEeVcpRUCCIPG4qdzDA:1726508267914&tbas=0&source=lnt&sa=X&ved=2ahUKEwjI5orAgMiIAxWwRKQEHfqqJpIQpwV6BAgDEAY&biw=1366&bih=607&dpr=1
*/

@media (max-width: 500px) {

    .job-post-container .fields-section .row-wrap .input-itself.small {
        padding: 15px 20px;
        height: 55px;
        /* padding: 15px 20px; */
        border: solid 1px #cfdbed;
        border-radius: 10px;
        box-shadow: 0 7px 15px rgba(61, 70, 83, .1);
        background-color: #fff;
        display: block;
        width: 100%;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
    }
    
    .job-post-container .fields-section .row-wrap .input-itself::placeholder {
        font-size: 12px;
        white-space:pre-line;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    .job-post-container .fields-section .row-wrap .country-section ,
    .job-post-container .fields-section .row-wrap .category-section , 
    .job-post-container .fields-section .row-wrap .employmenttpye-section ,
    .job-post-container .fields-section .row-wrap .workplace-section ,
    .job-post-container .fields-section .row-wrap .gender-section,
    .job-post-container .fields-section .row-wrap .job-title-section ,
    .job-post-container .fields-section .row-wrap .salary-title-section,
    .job-post-container .fields-section .row-wrap .company-emailsubject-section,
    .job-post-container .fields-section .row-wrap .job-description-section {
        flex: 0 0 auto;
        width: 100%;
    }

    .job-post-container .fields-section .row-wrap .field-comp-pr {
        padding-right: 0 !important;
    }
    
    .job-post-container .fields-section .row-wrap .field-comp-pl {
        padding-left: 0 !important;;
    }

    .job-post-container .page-container .title-section .title h2{
        font-size: 16px;
    }

    .job-post-container #div-apply {
        height: 37%;
        min-height: 50px;
        gap: 20px;
        width: 100%;
    }

    .job-post-container .job-btn-itself {
        margin-left: auto;
    }

    .job-post-container .fields-section .row-wrap .pay-info-and-post {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .job-post-container .fields-section .row-wrap .pay-info-and-post .pay-info {
        display: none !important;
    }

    .job-post-container .fields-section .row-wrap .pay-info-and-post .pay-info-mobile {
        font-size: 11px;
        font-style: italic;
        color: #c1c1c1;
        /* display: block; */
    }

}











/* .job-post-container td input {
    border: solid 1px #b1b1b1;
    border-radius: 3px;
    padding: 4px;
}

.job-post-container input.input-dark-style {
    background-color: rgb(68, 68, 70, 0.5);
    color: white;
    border: solid 0;
    padding: 10px;
    width: 300px;
}*/