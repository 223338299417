

.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 10;
    background-color: #f0f6fe;

}

.login-container .container-fluid {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;

    width: 100%;
    padding-right: var(--bs-gutter-x, .75rem);
    padding-left: var(--bs-gutter-x, .75rem);
    margin-right: auto;
    margin-left: auto;
}

.login-container .container-fluid .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1* var(--bs-gutter-y));
    margin-right: calc(-.5* var(--bs-gutter-x));
    margin-left: calc(-.5* var(--bs-gutter-x));
}

.login-container .row .left-side {
    background-color: #f0f6fe;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    width: 66.66666667%;
}

.login-container .row .twm-log-reg-media {
    padding: 40px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.login-container .row .twm-l-media {
    max-width: 1100px;
    margin: 0 auto;
}

.login-container .row .left-side img {
    width: 100%;
    height: auto;
    max-width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none;
    vertical-align: middle;
}


 /* Right side */
.login-container .row .page-loader {
    flex: 0 0 auto;
    width: 33.33333333%;
    background: white;
}

.login-container .row .page-loader .twm-log-reg-form-wrap {
    padding: 30px 45px;
    border-radius: 10px;
    background-color: #fff;
}

.login-container .row .page-loader .twm-log-reg-form-wrap .twm-log-reg-logo-head {
    text-align: center;
    border-bottom: 1px solid #efefef;
    padding-bottom: 30px;
    margin-bottom: 50px;
}

.login-container .row .page-loader .twm-log-reg-form-wrap .twm-log-reg-logo-head .twm-log-reg-head {
    text-align: center;
}

.login-container .row .page-loader .twm-log-reg-form-wrap .twm-log-reg-head .log-reg-form-title {
    font-size: 32px;
    display: block;
    margin-bottom: 20px;
    font-weight: 600;
    color: rgb(85, 85, 85);
    text-align: center;
    font-weight: 700;
}

/* .twm-tabs-style-2 .tab-content {
    text-align: center;
} */





.login-container .twm-tabs-style-2 .tab-content .tab-pane {
    display: block;
}

.login-container .twm-tabs-style-2 .tab-content .tab-pane .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1* var(--bs-gutter-y));
    margin-right: calc(-.5* var(--bs-gutter-x));
    margin-left: calc(-.5* var(--bs-gutter-x));
    width: 100%;

}


.login-container .twm-tabs-style-2 .tab-content .tab-pane .row form {
    width: 100%;
}

.login-container .twm-tabs-style-2 .tab-content .tab-pane .row .login-fields {
    display: flex;
    flex-direction: column;
    align-items: self-start;
    justify-content: space-around;
    width: 100%;
    margin-top: 40px;
}

.login-container .twm-tabs-style-2 .tab-content .tab-pane .row .login-fields .element {
    width: 100%;
    margin-bottom: 25px;
}


.login-container .twm-tabs-style-2 .tab-content .tab-pane .row .login-fields .input-field:focus {
    border: solid 1px #8ab5f5;
    outline: none;
}


.login-container .twm-tabs-style-2 .tab-content .tab-pane .row .login-fields .input-field {
    width: 100%;
    height: 55px;
    padding: 15px 20px;
    border: 1px solid #cfdbed;
    border-radius: 10px;
    box-shadow: 0 7px 15px rgba(61, 70, 83, .1);
    background-color: #fff;
    display: block;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    width: 100%;
}

.login-container .twm-tabs-style-2 .tab-content .tab-pane .row .login-fields .show-hide-eye {    
    position: absolute;
    cursor: pointer;
    left: 81%;
    top: 0;
    width: 55px;
    height: 55px;
    z-index: 5;
    line-height: 55px;
    text-align: center;
    font-size: 24px;
    color: #1967d2;
}


.login-container .forgot-password {
    color: blue;
    font-size: 15px;
    cursor: pointer;
    font-weight: 500;
    border: none;
    background: transparent;
}

.login-container .twm-tabs-style-2 .tab-content .tab-pane .row .signup-login {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    gap: 15px;
}

.login-container .twm-tabs-style-2 .tab-content .tab-pane .row .signup-login .signup-div,
.login-container .twm-tabs-style-2 .tab-content .tab-pane .row .signup-login .login-div {
    /* width: 44%; */
    width: 100%;
    color: white;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 40px;
    font-weight: 600;
}

.login-container .twm-tabs-style-2 .tab-content .tab-pane .row .signup-login .login-submit,
.login-container .twm-tabs-style-2 .tab-content .tab-pane .row .signup-login .signup-submit {
    border: none;
    cursor: pointer; 
    background: #1967d2; 
    color: white;
    padding: 10px 12px;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: 600;
}

.login-container .twm-tabs-style-2 .tab-content .tab-pane .row .signup-login .login-submit {
    font-size: 15px;
}

.login-container .twm-tabs-style-2 .tab-content .tab-pane .row .signup-login .or-div{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-container .twm-tabs-style-2 .tab-content .tab-pane .row .signup-login .or-div .or-text{
    width: 100%;
    background: white;
    color: #9d9d9d;
    text-align: center;
}

.login-container .twm-tabs-style-2 .tab-content .tab-pane .row .signup-login .or-div .hor-line {
    width: 100%;
    height: 1px;
    background: #9d9d9d;
}

.login-container .twm-tabs-style-2 .tab-content .tab-pane .row .signup-login .signup-submit {
    text-decoration: none;
    font-size: 15px;
}


.login-container .disabled {
    background-color: #d5d5d5 !important;
    color: #9d9d9d !important;
    /* text-shadow: 0 0 0 #fff, 400px 0 0 #fff; */
    cursor: not-allowed !important;
}


.login-container .footer {
    color: #fff;
    background-color: #232323;
    width: 100%;
}



/* Reset Password page */

.login-container .three-rows {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.login-container .three-rows .email-div,
.login-container .three-rows .back-to-login-div,
.login-container .three-rows .reset-password-div {
    width: 100%;
    margin-bottom: 22px;
}

.login-container .three-rows .email-itself:focus {
    border: solid 1px #8ab5f5;
    outline: none;
}

.login-container .three-rows .email-itself {
    width: 100%;
    height: 55px;
    padding: 15px 20px;
    border: 1px solid #cfdbed;
    border-radius: 10px;
    box-shadow: 0 7px 15px rgba(61, 70, 83, .1);
    background-color: #fff;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-clip: padding-box;
}

.login-container .three-rows .back-to-login-itself {
    border: none;
    background: white;
    color: #1967d2;
    cursor: pointer;
    font-size: 17px;
}


.login-container .three-rows .reset-password-itself {
    outline: 0;
    padding: 15px 30px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    display: inline-table;
    background-color: #1967d2;
    border: none;
    /* font-weight: 600; */
    font-size: 16px;
    color: transparent !important;
    text-shadow: 0 0 0 #fff, 400px 0 0 #fff;
    width: 100%;
}


/* if user is logged in, and navigate to /login", just show him a message */

.login-container .loggedin-block {
    /* margin: 10px 0 10px 10px; */
    border: solid 1px #ffeeb5;
    border-radius: 5px;
    background: #FFF3CD;
    width: 80%;
    height: 61px;
    margin-top: -200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 20px;
}


.login-container .back {
    border: none;
    color: blue;
    margin-left: 15px;
    cursor: pointer;
    background: transparent;
}



@media (max-width: 500px) {

    .login-container .container-fluid {
        margin-bottom: 10px;
    }

    .login-container .row .left-side, 
    .login-container .row .page-loader,
    .login-container .row .twm-log-reg-media {
        width: 100%;
    }

    .login-container .row .page-loader {
        border-radius: 5px;
    }

    .login-container .row .twm-log-reg-media {
        padding: 0 0 14px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .login-container .row .twm-l-media {
        width: 57%;
    }

    .login-container .row .page-loader .twm-log-reg-form-wrap {
        /* padding: 30px 9px 30px 34px; */
        /* padding: 18px 9px 0 35px; */
        padding: 18px 9px 25px 35px;
    }

    .login-container .row .page-loader .twm-log-reg-form-wrap .twm-log-reg-logo-head {
        margin-bottom: 15px;
        width: 91%;
        display: none;
    }

    .login-container .twm-tabs-style-2 .tab-content .tab-pane .row .login-fields {
        margin-top: 10px;
    }

    .login-container .three-rows {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem !important;
    }

    .login-container .three-rows .email-itself {
        width: 100%;
        height: 55px;
        padding: 15px 20px;
        border: 1px solid #cfdbed;
        border-radius: 10px;
        box-shadow: 0 7px 15px rgba(61, 70, 83, .1);
        background-color: #fff;
        display: block;
        font-size: 14px !important;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        background-clip: padding-box;
    }

    .login-container .three-rows .back-to-login-itself {
        border: none;
        background: white;
        color: #1967d2;
        cursor: pointer;
        font-size: 14px !important;
    }

    .login-container .twm-tabs-style-2 .tab-content .tab-pane .row .login-fields .show-hide-eye {    
        position: absolute;
        cursor: pointer;
        left: 81%;
        top: 0;
        width: 34px;
        height: 55px;
        z-index: 5;
        line-height: 55px;
        text-align: center;
        font-size: 18px;
        color: #1967d2;
    }

    .login-container .row .page-loader .twm-log-reg-form-wrap .twm-log-reg-head .log-reg-form-title {
        font-size: 32px;
        display: block;
        margin-bottom: 20px;
        font-weight: 600;
        color: rgb(85, 85, 85);
        text-align: center;
        font-weight: 700;
        margin-right: 19px;
    }

    .login-container .twm-tabs-style-2 .tab-content .tab-pane .row .login-fields .input-field {
        width: 100%;
        height: 55px;
        padding: 15px 20px;
        border: 1px solid #cfdbed;
        border-radius: 10px;
        box-shadow: 0 7px 15px rgba(61, 70, 83, .1);
        background-color: #fff;
        display: block;
        width: 100%;
        font-size: 14px !important;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        width: 100%;
    }

    .login-container .twm-tabs-style-2 .tab-content .tab-pane .row .signup-login .signup-submit {
        text-decoration: none;
        font-size: 15px;
    }

    .login-container .three-rows .reset-password-div {
        width: 100%;
        margin-bottom: 22px;
        height: 40px;
        /* border: solid 1px red; */
    }

    .login-container .three-rows .reset-password-itself {
        outline: 0;
        padding: 0 !important;
        border-radius: 5px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        display: inline-table;
        background-color: #1967d2;
        border: none;
        /* font-weight: 600; */
        font-size: 16px;
        color: transparent !important;
        text-shadow: 0 0 0 #fff, 400px 0 0 #fff;
        width: 100%;
        height: 100%;
        max-height: 100%;
        min-height: 100%;
    }

    .login-container .footer {
        color: #fff;
        background-color: #232323;
        width: 100%;
        margin-bottom: 40px;   
    }
}





