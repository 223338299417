

/* .big-home-container {
    margin-top: 60px;
} */

.big-home-container.mobile-version {
    display:none;
}

.home-container {
    display: flex;
    flex-direction: row;
    /* align-self: center; */
    justify-content: center;
    z-index: 1;

    /*text-align: center;
    height: 100%;
    background: linear-gradient(180deg, #052d53, rgb(112 111 129), #052d53);
    color: whitesmoke;*/
}

.home-container .big-circle {
        background: rgb(106 201 211 / 11%);
        width: 600px;
        z-index: 4;
        height: 600px;
        border-radius: 50%;
        position: absolute;
        margin-right: auto;
        margin-left: auto;
        right: 63px;
        top: -220px;
}

.home-container .white-circle {
    background: white;
    width: 450px;
    z-index: 4;
    height: 450px;
    border-radius: 50%;
    margin-right: auto;
    margin-left: auto;
    position: absolute;
    top: -145px;
    right: 133px;
}


.home-container .texts {
    position: absolute;
    top: 200px;
    left: 130px;
    display: flex;
    flex-direction: column;
    z-index: 5;
    /* border: solid 1px black; */
    gap: 2rem;
}

/* .home-container .texts div {
    border: solid 1px black;
} */

.home-container .texts .text1 {
    font-size: 16px;
    display: flex;
    flex-direction: row;
    /* border: solid 1px black; */
    align-items: center;
}

.home-container .texts .right-sign {
    background: rgb(240, 181, 40);
    width: 43px;
    height: 43px;
    display: inline-block;
    border-radius: 12px;
    margin-right: 10px;
    color: white;
    font-size: 23px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-container .texts .website-word {
    color: #1965CF;
    font-style: italic;
    font-weight: 600;
}

.home-container .texts .text2 {
    font-size: 72px;
    font-weight: 600;
    letter-spacing: 2px;
}

.home-container .texts .text2 span.job-word {
    background: blue;
    color: white;
    font-family: monospace;
    padding: 0 9px;
    border-radius: 11px;
}


.home-container .texts .text3 {
    font-size: 19px;
    color: #696a6a;
}

.home-container .texts .find-job-button {
    background: #3c3cf1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    width: 191px;
    height: 30px;
    border-radius: 10px;
    padding: 30px;
    font-size: 28px;
    margin: 3px 0 0 350px;
    cursor: pointer;
}

.home-container .texts .find-job-button a {
    color: white;
    text-decoration: none;
}

.home-container .handsome-img {
    width: 325px;
    height: 562px;
    z-index: 10;
    position: absolute;
    right: 182px;
    top: -200px;
}

.home-container div.plus-sign {
    font-size: 25px;
    z-index: 10;
    position: absolute;
    border-radius: 50%;
    background: #ffb100b3;
    right: 70px;
    bottom: -234px;
    color: white;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    animation: MoveUpDown 3.8s linear infinite;
}

.home-container i.search-sign {
    font-size: 76px;
    z-index: 10;
    position: absolute;
    right: 140px;
    top: -181px;
    color: #00d9ff85;

    animation: MoveUpDown 2.8s linear infinite;

}

.home-container div.upload-div {
    
    padding: 11px;
    color: white;
    gap: 14px;
    z-index: 10;
    position: absolute;
    font-size: 20px;
    right: 502px;
    bottom: 54px;
    background: #fe8666c7;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    animation: MoveUpDown 3.4s linear infinite;
    
}

.home-container .paperclip {
    font-size: 34px;
}


.home-container span.upload-cv {
    width: 89px;
    font-size: 16px;
    font-family: cursive;
}



.home-container .pdf-icon {
    color: red;
    font-size: 62px;
    z-index: 8;
    position: absolute;
    right: 550px;
    bottom: -159px;

    animation: MoveUpDown 3s linear infinite;
}
      
@keyframes MoveUpDown {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}


.jobs-statistics-section {
    height: 300px;
    /* border: solid 1px red; */
    width: 100%;
    background: rgb(0, 0, 255, 50%);
    z-index:11;
}

.jobs-statistics-section .div-data {
    position: relative;
    top: 297px;
    margin-right: auto;
    margin-left: auto;
    width: 80%;
    background: #17171D;
    border-radius: 30px;
    height: 250px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 9rem;
    color: #cbcbcb;
    z-index: 3;
}

.jobs-statistics-section div.counting {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.jobs-statistics-section .number {
    font-size: 50px;
    font-weight: 600;
}

.jobs-statistics-section .companies {
    color: #796BC5;
}

.jobs-statistics-section .user-applications {
    color: #FFE119;
}

.jobs-statistics-section .categories {
    color: #56D8B1;
}

.jobs-statistics-section .title {
    font-size: 19px;
}

.jobs-statistics-section .posted-jobs {
    color: #B17CBF;
}

.jobs-statistics-section .logo {
    font-size: 50px;
    z-index: 2;
    margin-bottom: 15px;
}

.jobs-statistics-section .rotated-div {
    background: #1965cfc2;
    border-radius: 30px;
    transform: rotate(318deg);
    position: absolute;
    height: 40px;
    width: 92px;
    z-index: 1;
    top: 56px;
}

.jobs-statistics-section .bottom-img {
    position: absolute; 
    height: 430px;
    width: 100%;
}

.jobs-statistics-section .bottom-bg {
    position: absolute;
    background: #291dd5bd;
    color: white;
    height: 430px;
    z-index: 1;
    padding-left: 10%;
    padding-top: 4%;
    width: 100%;
}



.jobs-statistics-section2 {
    height: 320px;
    /* border: solid 1px red; */
    width: 100%;
    /* background: rgb(0, 0, 255, 50%); */
}

.jobs-statistics-section .join-community {
    font-size: 40px;
    font-weight: 800;
    font-family: monospace;
    margin-top: 17px;
}

.home-container .light-color {
    background: rgb(228, 253, 228);
    width: 80%;
    height: 712px;
    z-index: 2;
}

.home-container .dark-color {
    background: rgb(33, 33, 120);
    width: 20%;
    height: 712px;
    z-index: 2;
}

.home-container .handsome-icons {
    display: flex;
    z-index: 3;
    flex-direction: row;
    align-self: center;
    justify-content: center;
    position: absolute;
    right: 0px;
    top: 410px;
}



/* my mobile =  Resolution, width: 360, height: 512 pixels      
from https://www.google.com/search?q=samsung+galaxy+a02+core+dimensions+in+px&sca_esv=5e5568ccfe61287d&sca_upv=1&sxsrf=ADLYWII6uHR2k1JHEeVcpRUCCIPG4qdzDA:1726508267914&tbas=0&source=lnt&sa=X&ved=2ahUKEwjI5orAgMiIAxWwRKQEHfqqJpIQpwV6BAgDEAY&biw=1366&bih=607&dpr=1
*/

@media (max-width: 500px) {

    .big-home-container.desktop-version {
        display:none;
    }

    .big-home-container.mobile-version {
        display: block;
        /* background: linear-gradient(135deg, #71b7e6, #9b59b6); */
        background: linear-gradient(135deg, #71b7e63b, #9b59b629);
        flex-direction: column;
    }
    
    .mobile-version .nav-bar-mobile {
        position: fixed;
        top: 0;
        width: 100%;
        border-bottom: solid 1px #f1f1f1;
        height:70px;
        background: rgb(244 248 253);
        z-index: 50;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        gap: 28px;
    }




    .mobile-version .handsome-man-and-icons {
        position: relative;
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        height: 100vw;
        /* height: calc(100vh - 137px) !important; */
        border-bottom: solid 1px rgba(106, 200, 211, 0.5);
        top: 70px;
        margin-top: 27px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .mobile-version .big-circle {
        position: absolute;
        background: rgba(106, 200, 211, 0.178);
        width: 100vw;
        height: 100vw;
        z-index: 4;
        border-radius: 50%;
        margin-right: auto;
        margin-left: auto;
    }

    .mobile-version .white-circle {
        position: absolute;
        background: rgb(250, 250, 250);
        width: 75vw;
        height: 75vw;
        z-index: 4;
        border-radius: 50%;
        margin-right: auto;
        margin-left: auto;
    }

    .mobile-version .handsome-img {
        position: absolute;
        width: 65%;
        height: 100%;
        left: 19vw;
        z-index: 7;
    }

    .mobile-version .handsome-man-and-icons .orange-circle {
        position: absolute;
        bottom: 14%;
        z-index: 6;
        left: 8%;
        width: 13vw;
        height: 13vw;
        border: 8px solid #ff8667;
        border-radius: 50%;
        animation: MoveUpDown 3s linear infinite;
        top: 110px;
    }

    .mobile-version i.search-sign {
        position: absolute;
        bottom: 14%;
        z-index: 6;
        right: 1%;
        width: 15vw;
        height: 15vw;
        /* border: 8px solid #ff8667; */
        border-radius: 50%;
        animation: MoveUpDown 2.6s linear infinite;
        top: 225px;
        font-size: 35px;
        z-index: 20;
        position: absolute;
        color: #ffb978;
    }

    .mobile-version div.plus-sign {
        color: #1967d2;
        font-size: 24px;
        position: absolute;
        top: 25%;
        z-index: 6;
        right: 20px;
        animation: MoveUpDown 4s linear infinite;
    }

    .mobile-version div.plus-sign2 {
        color: #f0b528;
        font-size: 30px;
        position: absolute;
        top: 55%;
        z-index: 6;
        left: 30px;
        animation: MoveUpDown 4s linear infinite;
    }

    .mobile-version .pdf-icon {
        animation: MoveUpDown 4s linear infinite;
        font-size: 45px;
        z-index: 20;
        color: #ff0000;
        position: relative;
        right: -28%;
        top: -165px;
        width: 20px;
        height: 20px;
    }

    .mobile-version div.upload-div {
        animation: MoveUpDown 4s linear infinite;
        padding: 6px;
        color: white;
        font-size: 10px !important;
        z-index: 20;
        position: absolute;
        background: #ff7954;
        /* right: 39%;
        top: -294px; */
        height: 40px;
        border-radius: 4px;
        width: fit-content;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 15px;
        top: 86vw;
        right: 10%;
    }

    .mobile-version .paperclip {
        font-size: 15px !important;
    }
    
    .mobile-version span.upload-cv {
        width: 80px;
        font-size: 15px !important;
        font-family: cursive;
    }






    .mobile-version .texts { 
        /* border: solid 1px black;
        height: 50px;
        position: relative;
        height: max-content; */
        
        width: 90%;
        margin-right: auto;
        margin-left: auto;
        position: relative;
        top: 25vw;
        /* padding: 10px; */
        display: flex;
        flex-direction: column;
        z-index: 5;
        gap: 20px;
        height: max-content;
        /* border: solid 1px red; */
    }

    .mobile-version .texts .text1 {
        margin-top: 25px;
        display: flex;
        flex-direction: row;
        font-size: 16px;
        align-items: center;
    }

    .mobile-version .texts .text1 .right-sign {

        display: inline-block;
        background: rgb(240, 181, 40);
        margin-right: 15px;
        color: white;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        width: 18px;
        height: 18px;
        border-radius: 4px;
        padding: 21px;
        font-size: 29px;
    }

    .mobile-version .texts .text1 .stay-connect {
        font-size: 16px;
    }

    .mobile-version .texts .text2 {
        font-size: 28px;
    }

    .mobile-version .texts .text2 .job-word{
        background: rgb(77 77 255);
        color: white;
        font-family: monospace;
        padding: 0 9px;
        font-size: 28px;
        border-radius: 4px;
    }
    
    .mobile-version .texts .text3 {
        font-size: 18px;
        color: #696a6a;

        /*background: rgb(77 77 255);
        color: white;
        font-family: monospace;
        padding: 0 9px;
        font-size: 28px;
        border-radius: 4px;*/
    }
    
    .mobile-version .texts .find-job-button {
        background: rgb(77 77 255);
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        width: 100%;
        height: 49px;
        border-radius: 5px;
        /* padding: 25px; */
        font-size: 18px;
        font-weight: 700;
        /* margin: 3px 0 0 350px; */
        /* cursor: pointer; */
        margin-top: 20px;
    }
    
    .mobile-version .texts .find-job-button a {
        color: rgb(240, 240, 240);
        text-decoration: none;

        width: 100%;
        /* margin-left: auto; */
        /* margin-right: auto; */
        text-align: center;
        height: 100%;
        padding-top: 12px;
    }


    




    .mobile-version .div-data {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-top: 28vw;
        /* margin-bottom: 70px; */
    }

    .mobile-version .counting {
        /* background-color: #17171d; */
        background-color: #020230;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        gap: 30px;
        margin: 10px;
        width: 90%;
        padding: 0 30px;
        height: 110px;
        /* padding: 20px;
        text-align: center;
        flex: 1;
        min-width: 200px; */
    }

    .mobile-version .counting .col1 {
        min-width: 30%;
        padding-bottom: 20px;
        align-items: center;
    }

    .mobile-version .counting .col2 {
        align-items: self-end;
    }

    .mobile-version .counting .col1,
    .mobile-version .counting .col2 {
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        justify-content: center;
    }

    .mobile-version .counting .number {
        font-size: 7vh;
        font-weight: 600;
    }

    .mobile-version .counting .title {
        font-size: 19px;
        color: white;
        width: max-content;
    }

    .mobile-version .counting .categories {
        color: #56D8B1;
    }
    
    .mobile-version .counting .user-applications {
        color: #FFE119;
    }

    .mobile-version .counting .posted-jobs {
        color: #B17CBF;
    }

    .mobile-version .counting .companies {
        color: #796BC5;
    }
    
    .mobile-version .div-data .counting .logo {
        /* font-size: 50px; */
        font-size: 44px;
        z-index: 2;
        margin-bottom: 15px;
        /* color: white; */
        color: #e6f7fe;
        top: -3px;
        position: relative;
    }
    
    .mobile-version .rotated-div {
        background: #1965cfc2;
        border-radius: 30px;
        transform: rotate(318deg);
        position: relative;
        height: 40px;
        width: 92px;
        z-index: 1;
        top: 50px;
    }

    .mobile-version hr {
        margin-top: 150px !important;
    }





    /*
    .home-container {
        height: 38%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        z-index: 1;
    }

    .home-container .light-color {
        background: rgb(228, 253, 228);
        width: 80%;
        height: 100%;
        z-index: 2;
    }

    .jobs-statistics-section2 {
        height: 320px;
        width: 10%;
        display: none;
    }

    .jobs-statistics-section .number {
        font-size: 25px;
        font-weight: 600;
    }

    .jobs-statistics-section .logo {
        font-size: 50px;
        z-index: 2;
        margin-bottom: 15px;
    }
    
    .home-container .dark-color {
        background: rgb(33, 33, 120);
        width: 20%;
        height: 100%;
        z-index: 2;
    }

    .home-container .texts {
        position: absolute;
        top: 32px;
        left: 10px;
        display: flex;
        flex-direction: column;
        z-index: 5;
        gap: 1rem;
    }

    .home-container .texts .text1 {
        font-size: 12px;
    }

    .home-container .texts .right-sign {
        width: 13px;
        height: 13px;
        border-radius: 4px;
        padding: 13px;
        font-size: 15px;
    }

    .home-container .handsome-icons {
        display: flex;
        z-index: 3;
        flex-direction: row;
        align-self: center;
        justify-content: center;
        position: absolute;
        right: 0px;
        top: 410px;
        height: 34%;
        width: 100%;
    }

    .home-container .texts .text2 {
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 2px;
    }

    .jobs-statistics-section .bottom-img {
        position: relative;
        height: 48%;
        width: 100%;
        top: 316px;
        z-index: 11;
        display: none;

    }
    
    .jobs-statistics-section .bottom-bg {
        top: 56%;

        position: relative;
        background: #261bc1bd;
        color: white;
        height: 48%;
        z-index: 12;
        padding-left: 10%;
        padding-top: 4%;
        width: 100%;

        display: none;
    }

    .jobs-statistics-section .join-community {
        font-size: 14px !important;
        font-weight: 800;
        font-family: monospace;
        margin-top: 17px;
    }

    .jobs-statistics-section {
        height: 926px;
        width: 100%;
        background: rgba(255, 255, 255, 0) !important;
        z-index:11;
    }

    .jobs-statistics-section .div-data {
        position: relative;
        top: 240px;
        width: 95%;
        background: #17171D;
        border-radius: 7px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        color: #cbcbcb;
        z-index: 3;
        gap: 0;
        height: 723px;
    }

    .jobs-statistics-section .rotated-div {
        background: #1965cfc2;
        border-radius: 30px;
        transform: rotate(318deg);
        position: relative;
        height: 40px;
        width: 92px;
        z-index: 1;
    }

    .home-container .texts .find-job-button {
        display: none;
    }

    .home-container .handsome-img {
        width: 43%;
        height: 61%;
        z-index: 10;
        position: relative;
        right: -28%;
        top: -380px;
    }

    .home-container .texts .text3 {
        font-size: 12px;
        width: 50%;
    }

    .home-container .big-circle {
        background: rgb(106 201 211 / 11%);
        width: 160px;
        z-index: 4;
        height: 160px;
        border-radius: 50%;
        position: absolute;
        margin-right: auto;
        margin-left: auto;
        right: 0;
        top: -355px;
    }

    .home-container .white-circle {
        background: white;
        width: 120px;
        z-index: 4;
        height: 120px;
        border-radius: 50%;
        margin-right: auto;
        margin-left: auto;
        position: absolute;
        top: -332px;
        right: 19px;
    } */

}



@media (max-width: 299px) {
    
    .home-container .pdf-icon {
        color: red;
        font-size: 62px;
        z-index: 8;
        position: absolute;
        right: 550px;
        bottom: -159px;
        
        animation: MoveUpDown 3s linear infinite;
    }

    .home-container div.plus-sign {
        font-size: 25px;
        z-index: 10;
        position: absolute;
        border-radius: 50%;
        background: #ffb100b3;
        right: 70px;
        bottom: -234px;
        color: white;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    
        animation: MoveUpDown 3.8s linear infinite;
    }
    
    .home-container i.search-sign {
        font-size: 76px;
        z-index: 10;
        position: absolute;
        right: 140px;
        top: -181px;
        color: #00d9ff85;
        animation: MoveUpDown 2.8s linear infinite;
    }

    .home-container .handsome-img {
        display: none;
    }

    .home-container .big-circle {
        background: rgb(106 201 211 / 11%);
        width: 160px;
        z-index: 4;
        height: 160px;
        border-radius: 50%;
        position: absolute;
        margin-right: auto;
        margin-left: auto;
        right: 0;
        top: -355px;
    }

    .home-container .white-circle {
        background: white;
        width: 120px;
        z-index: 4;
        height: 120px;
        border-radius: 50%;
        margin-right: auto;
        margin-left: auto;
        position: absolute;
        top: -332px;
        right: 19px;
    }

    .home-container span.upload-cv {
        width: 65px;
        font-size: 11px;
        font-family: cursive;
    }

    

}












