
.gauge {
    
    position: relative;
    border-radius: 50%;
    width:150px;
    height: 150px;
    margin: 41px 137px;
    z-index: 1;
    
    
    /* new properties */
    /* background-image: linear-gradient(-90deg, red, #ffe000, green); */
}

.gauge .inside {
    border-radius: 50%;
    position: absolute;
    width:110px;
    height: 110px;
    top: 20px;
    left: 20px;
    z-index: 5;
    background: white;
}

.point-center {
    background: black;
    border-radius: 50%;
    position: absolute;
    top: 53px;
    left: 53px;
    z-index: 10;
    width:4px;
    height: 4px;
}