

.component {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    width: 100%;
}

.available-elts {
    margin: 10px 0 30px 30px;
    width: 110px;
}

.selected-side {
    /* position:absolute; */
    top:10px;
    left:170px;
}

.selected-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin: 8px 0;
    padding:8px;

    color:aliceblue;
    width:110px;
    background-color: rgb(65, 112, 173);
    border-radius: 3px;
}

.selected-text {
    font-size:smaller;
    font-weight: 700;
}

/* .x-icon::before {
    content: "\2716";
    font-size:9px;
    color:white;
} */

.x-icon-container {
    display: flex;
}

.x-icon {
    cursor: pointer;

    font-size: 15px;
    color: white;

    margin-top: -18px;
}


