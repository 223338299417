


.server-call-container {
    background: white;
}

.table-content table, .table-content th, .table-content td {
    border: solid 1px rgb(233, 233, 233);
}

.table-content th {
    background-color: rgb(248, 217, 177);
}

.table-content tr:nth-child(even) {
    background-color: rgb(248, 241, 233);
}

.table-content table {
    width: 100%;
}

.server-call-container .active-link {
    background: rgb(250, 169, 169);
}