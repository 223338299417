


.admin-container .header {
    display: inline-block;
    text-align: center;
    font-weight: 600; 
}

.admin-container .value {
    display: inline-block;
    text-align: center;
    /* font-weight: 600;  */
}

.admin-container .single-row {
    margin: 7px 0;
}

.admin-container .single-row:nth-child(even) {
    background: rgb(205, 211, 209);
}

.admin-container span {
    border-right: solid 1px rgb(221, 221, 221);
    border-bottom: solid 1px rgb(221, 221, 221);
}




/* example of how to write responsive css */

@media (max-width: 500px) {

    .admin-container .header {
        display: none;
    }

    .admin-container.every-page-container {
        position: relative;
        width: 100%;
        top: 170px;
    }
    #numbers123456 {
        position: absolute !important;
        top: 10px !important;
        padding: 14px 0;  
    }
}


