
/* 
@keyframes example {

    0%   { bottom: -50px;}
    20%  { bottom: 20px;}
    80%  { bottom: 20px;}
    100% { bottom: -50px;}
} */

.toast-container {

    /* border: solid 1px rgb(219, 219, 219);  */
    /* height: 250px; */
    /* min-height: 250px; */
    position: fixed;
    z-index: 40;
    width: 250px;
    min-width: 250px;
    bottom: 30px;
    right: 85px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 7px;
    height: fit-content;
    /* transform: translateY(-500px);
    transition: 0.4s; */
}


/* .react-toast-container.show {
    transform: translateY(0px);
} */

.toast {
    /* position: fixed;
    bottom: -50px;
    right: 20px;
    border: solid 1px;
    background: red;
    color: white;
    border-radius: 5px;
    width: 200px;
    height: 50px;
    animation-name: example;
    animation-duration: 5s; */
    /* position: fixed;
    bottom: 20px;
    right: 20px; */

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 300px;
    min-width: 300px;
    height: 63px;
    /* color: rgb(255, 255, 255); */
    z-index: 100;
    border-radius: 5px;
}

.toast.success-bg{
    background: rgb(222 255 210);
}

.toast.error-bg{
    background: #ffe5e5;
}

.toast.info-bg{
    background: #eeeeff;
}

.toast.warning-bg {
    background: #ffe9c2;
}

.toast .success {
    background: rgb(74, 170, 77);
}

.toast .error {
    background: rgb(243, 0, 0);
}

.toast .info {
    background: rgb(67, 142, 255);
}

.toast .warning {
    background: rgb(255 188 0);
}

.toast .success-btn {
    color: rgb(74, 170, 77);
}

.toast .error-btn {
    color: rgb(243, 0, 0);
}

.toast .info-btn {
    color: rgb(67, 142, 255);
}

.toast .warning-btn {
    color: rgb(255 188 0);
}

.toast .vertical-bar {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    height: 100%;
    width: 6px;
}

.toast .semi-circle-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.toast .semi-circle {
    width: 20px;
    height: 20px;
    color: white;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 13px;
}

.toast .title-and-message-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 14px;
}

.toast .title-and-message-col .title{
    font-size: 20px;
}

.toast-container .toast .message {
    font-size: 14px;
    /* margin-top: -6px; */
    font-weight: 500;
    width: 240px;
}

.toast .empty-space{
    width: 3px;
}

.toast .del-btn {
    width: 15px;
    height: 15px;
    /* border-radius: 50%; */
    font-size: 16px;
    /* border: solid 1px rgb(230, 229, 229); */
    /* color: rgb(74, 170, 77); */
    cursor: pointer;
    margin: 0 7px 5px 0;
}

/* .toast-container .toast.on {
    /* -webkit-animation: toast 0.6s 0.1s 1 linear alternate;
    -moz-animation: toast 0.6s 0.1s 1 linear alternate;
    -ms-animation: toast 0.6s 0.1s 1 linear alternate;
    -o-animation: toast 0.6s 0.1s 1 linear alternate; 
    animation: example;
} */


@media (max-width: 500px) {

    .toast {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        /* width: 200px;
        min-width: 200px; */
        width: 100%;
        min-width: 100%;
        height: 63px;
        z-index: 100;
        border-radius: 5px;
    }

    .toast-container {
        position: fixed;
        top: 15px;
        z-index: 40;
        left: 4%;
        width: 92%;
        min-width: 92%;

        /* width: 250px; */
        /* min-width: 250px; */
        /* bottom: 60px; */
        /* border: solid 1px red; */

        /* right: 10px; */
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 7px;
        height: fit-content;
    }

    .toast-container .toast .message {
        /* font-size: 12px; */
        font-size: 14px;
        /* margin-top: -6px; */
        font-weight: 400;
        width: 270px;
        color: rgb(42 42 42);
    }

    .toast .del-btn {
        width: 15px;
        height: 15px;
        /* border-radius: 50%; */
        font-size: 22px;
        /* border: solid 1px rgb(230, 229, 229); */
        /* color: rgb(74, 170, 77); */
        cursor: pointer;
        margin: 0 7px 16px 0;
    }
}