

input[type="file"] {
    /* font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    overflow: hidden; */
    
    display:none;

    /* new code */
    /* display:visible;   */
}

.file-uploader-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 7px;

  /* background: rgb(241, 241, 241);
  display: flex;
  flex-direction: column;
  border: dashed 2px #838383;
  border-radius: 5px;
  width: 450px;
  padding: 25px;
  margin-left: auto;
  margin-right: auto;
  gap: 7px; */
}

.file-uploader-container .upload-plus-accept {
  display: flex;
  flex-direction: column;
  align-items: normal;
  justify-content: space-between;
  gap: 13px;
}

.file-uploader-container .uploader-label {
  
  /* background-color: rgb(0 132 221); */
  background-color: #2478eb;
  
  box-shadow: 5px 5px 30px 7px rgb(114, 175, 86, 0.3);
  color: white;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
  text-align: center;
  /* font-family: Verdana; */
  font-size: 14px;
  width: 120px;
  min-width: 120px;
  max-width: 120px;
  
  /* background-color: rgb(114, 175, 86);  // green
  border-left: solid 3px rgb(93, 143, 70);
  border-bottom: solid 3px rgb(93, 143, 70);
  border-right: solid 3px rgb(93, 143, 70);
  box-shadow: 5px 5px 30px 1px rgb(114, 175, 86, 0.3);
  color:white;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  padding: 15px;
  text-align: center;
  font-family: Verdana;
  width: 210px;
  margin-left: auto;
  margin-right: auto;
  font-size: 12px; */
}

.file-uploader-container .selected-files {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-left: -36px;
}

/* a {
  text-decoration: none;
  float: right;
} */

.file-uploader-container i.delete-file, 
.file-uploader-container i.fa-cloud-upload{
  color: rgb(72, 186, 243);
  cursor: pointer;
  float: right;
}

.file-uploader-container i.fa-file-pdf-o {
  color: rgb(226, 40, 40);
  font-size: 40px;
}

.file-uploader-container i.fa-file-word-o {
  color: #295396;
  font-size: 40px;
}

.file-uploader-container i.fa-file-archive-o {
  color: rgb(255, 216, 87);
  font-size: 40px;
}

.file-container {
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;
}

img {
  width: 65px;
  height: 75px;
}