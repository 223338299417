


/* .search-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 3rem;
} */


.search-container .top-img {
    width: 100%; 
    height: 300px; 
    position: absolute;
}


.search-container .blue-div {
    width: 100%;
    height: 300px;
    color: white;
    position: relative;
    background: #0000ffad;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    font-weight: 500;
    /* margin-top: 60px; */
}

.search-container .home-jobslist {
    background: #1967D2;
    border-radius: 10px;
    color: white;
    position: relative;
    left: 43%;
    height: 30px;
    width: 180px;
    top: -26px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 191px;
    height: 51px;
    font-weight: 600;
    font-size: 16px;
}

.search-container .real-page-content {
    margin-top: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 46px 0 100px;
    gap: 92px;
}


.search-container .search-fields-area {
    width: 30%;
    background: #F0F6FE;
    padding: 30px;
    border-radius: 5px;
    max-width: 362px;
    min-width: 362px;
}

.search-container .search-fields-area .search-btn {
    background: #2478eb;
    width: 100%;
    font-size: 22px;
    color: white;
    font-weight: 600;
    border: 0 !important;
    padding: 16px 0;
    border-radius: 13px;
    cursor: pointer;
}

.search-container .search-fields-area .disabled {
    background-color: #d5d5d5;
    color: #9d9d9d;
    /* text-shadow: 0 0 0 #fff, 400px 0 0 #fff; */
    cursor: not-allowed;
}

.search-container .search-fields-area select {
    padding-left: 17px;
    border: solid 1px #b9b9f7;
    height: 59px;
    font-size: 16px;
    color: #606572;
    width: 100%;
    border-radius: 11px;
    margin-top: 15px;
}

.search-container .search-fields-area form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.search-container .search-field-title {
    color:#1967D2;
    font-size: 16px;
    font-weight: 500;

}

.search-container .jobs-list {
    display: flex;
    flex-direction: column;
    /* border: 1px solid black; */
    width: 69%;
    /* padding: 20px; */
    gap: 3rem;
}

.search-container .real-page-content div.empty-result {
    display: flex;
    flex-direction: row;
    background: #3c82e1;
    color: white;
    align-items: center;
    border-radius: 3px;
    height: 54px;
    width: 69%;
}


.search-container .real-page-content .small-white-ball {
    border-radius: 50%;
    width: 6px;
    height: 6px;
    background: white;
    /* z-index: 5; */
    margin: 0 13px;
}

.search-container .pagination-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    overflow: auto;
    width: 600px;
}

.search-container .pagination-block .one-link {
    background: #ffffff;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    padding: 11px 17px;
    cursor: pointer;
    color: #020202;
    border: solid 1px #e7e7e7;
}

.search-container .pagination-block .one-link.active-link {
    background: #1967D2;
    color: white;
    border: solid 1px #1967D2;
}

.search-container .pagination-block .one-link:hover:not(.active-link) {
    background: rgb(218, 234, 255);
    color: #0f4a92;
    border: solid 1px #134b99;
}

.search-container .single-job-row .col1 {
    display: block;
}

.search-container .single-job-row .col1.move-left {
    margin: 32px 0 0 -27px;
}

@media (max-width: 500px) {


    .search-container {
        width: 100% !important;
    }

    .search-container .top-img {
        width: 100%; 
        height: 125px; 
        position: absolute;
    }

    .search-container .blue-div {
        width: 100%;
        height: 125px;
        position: relative;
        font-size: 20px;
    }

    .search-container .search-fields-area .search-btn {
        background: #2478eb;
        width: 100%;
        font-size: 16px;
        color: white;
        font-weight: 600;
        border: 0 !important;
        padding: 0 !important;
        border-radius: 4px;
        height: 40px !important;
    }

    .search-container .search-fields-area .disabled {
        background-color: #d5d5d5;
        color: #9d9d9d;
        /* text-shadow: 0 0 0 #fff, 400px 0 0 #fff; */
        cursor: not-allowed;
    }

    .search-container .home-jobslist {
        background: #1967D2;
        border-radius: 10px;
        color: white;
        position: relative;
        left: 0;
        height: 30px;
        width: 180px;
        top: -26px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 191px;
        height: 51px;
        font-weight: 600;
        font-size: 16px;
        margin-left: auto;
        margin-right: auto;
    }

    .search-container .real-page-content {
        margin-top: 0 !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 0 !important;
        gap: 92px;
    }

    .recently-jobs {
        display: none;
    }

    .search-container .search-fields-area {
        background: #F0F6FE;
        border-radius: 5px;
        max-width: 100%;
        padding: 30px;
        width: 100%;
        min-width: 100%;
    }

    .search-container .jobs-list {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        padding: 10px;
        width: 100%;
    }

    .search-container .pagination-block {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        overflow: auto;
        width: 100%;
    }

}










/*
.search-container #search-icon, .search-container #clear-icon {
    position: absolute;
}

.search-container .search-input {
    padding: 5px 0 5px 30px;
    border-radius: 15px;
    border: solid 2px rgb(194, 183, 183);
}

.search-container #search-icon {
    left: 19px;
    bottom: 18px;
    color: rgb(194, 183, 183);
} 

.search-container #clear-icon {
    left: 180px;
    color: rgb(194, 183, 183);
}*/