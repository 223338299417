

.reco-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
}

.reco-container .textAsImg {
    height: 40px;
    width: 110px;
}

.reco-container .col1 {
    width: 100%;
}

.reco-container .entered-text {
    width: 100% !important;
}

.reco-container .refresh-icon {
    color: #0d63ff;
    font-size: 30px;
    cursor: pointer;
    width: 35px;
}


@media (max-width: 500px) {

    .reco-container {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .reco-container .col3 {
        padding-left: 30px;
    }

    .reco-container .col1 {
        width: 100%;
    }

    /* .reco-container .field input {
        width: 100% !important;
    } */

    .reco-container .refresh-icon {
        color: #0d63ff;
        font-size: 30px;
        cursor: pointer;
        width: 35px;
    }

    .reco-container input::placeholder {
        
        font-size: 12px;
        white-space:pre-line;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}