

.change-password-container {
    background: rgb(236, 242, 248);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px;
}

.change-password-container .title-section {
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    position: relative;
    padding: 15px 30px;
    margin-bottom: 30px;
    box-shadow: 0 20px 30px rgba(0, 51, 90, .05);
    border-radius: 10px;
}

.change-password-container .title-section .title h2 {
    font-size: 25px;
    color: #17171d;
    font-weight: 600;
    margin-bottom: 11px;
    font-family: Rubik, sans-serif, arial;
}

.change-password-container .title-section .path {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 11px;
}

.change-password-container .title-section .path .circle {
    border-radius: 50%;
    width: 5px;
    height: 5px;
    background: #17171d;
    margin-top: 4px;
}

.change-password-container .title-section .path .change-password {
    color: rgb(88, 88, 255);
}


.change-password-container .fields-section {
    padding: 30px 15px;
    background: white;
    border-radius: 10px;
}

.change-password-container .fields-section .row-wrap {
    display: flex;
    flex-wrap: wrap;
}

.change-password-container .fields-section .row-wrap .old-pass-section {
    flex: 0 0 auto;
    width: 50%;
}

.change-password-container .fields-section .row-wrap .new-pass-section {
    flex: 0 0 auto;
    width: 50%;
}

.change-password-container .fields-section .row-wrap .confirm-pass-section,
.change-password-container .fields-section .row-wrap .pass-strength-section {
    flex: 0 0 auto;
    width: 100%;
}


.change-password-container .fields-section .row-wrap div.pass-strength-section {
    font-style: italic;
    font-size: 12px;
    color: #c1c1c1;
    width: 100%;
    margin-bottom: 29px;
}

.change-password-container .fields-section .row-wrap .btn-save {
    flex: 0 0 auto;
    width: 100%;
}

.change-password-container .fields-section .field-comp-special {
    padding-left: 15px;
    padding-right: 30px;
}


.change-password-container .fields-section .field-comp {

    padding-left: 15px;
    padding-right: 15px;
}

.change-password-container .fields-section .comp-title {
    margin-bottom: 5px;
    color: #17171d;
    font-weight: 500;
}

.change-password-container .fields-section .field-container{
    position: relative;
}

.change-password-container .fields-section .lock-icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 55px;
    height: 55px;
    z-index: 5;
    line-height: 55px;
    text-align: center;
    font-size: 24px;
    color: #1967d2;
}


.change-password-container .fields-section .show-hide-eye-50 {      /*     50: 50%    */
    left: 88%;
}

.change-password-container .fields-section .show-hide-eye-100 {      /*     100: 100%    */
    left: 94%;
}

.change-password-container .fields-section .show-hide-eye-100 ,
.change-password-container .fields-section .show-hide-eye-50 { 
    position: absolute;
    cursor: pointer;
    top: 0;
    width: 55px;
    height: 55px;
    z-index: 5;
    line-height: 55px;
    text-align: center;
    font-size: 24px;
    color: #1967d2;
}

.change-password-container .fields-section .input-itself {
    padding-left: 55px;
    width: 100%;
    height: 55px;
    border: 1px solid #cfdbed;
    border-radius: 10px;
    box-shadow: 0 7px 15px rgba(61, 70, 83, .1);
    background-color: #fff;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
}

.change-password-container .fields-section .input-itself:focus {
    border: solid 1px #8ab5f5;
    outline: none;
}


.change-password-container .fields-section .row-wrap .btn-save {
    height: 98px;
    min-height: 98px;
}

.change-password-container .fields-section .row-wrap .disabled {
    background-color: #d5d5d5 !important;
    color: #9d9d9d !important;
    /* text-shadow: 0 0 0 #fff, 400px 0 0 #fff; */
    cursor: not-allowed !important;
}

.submit-btn-itself {
    
    text-shadow: 0 0 0 #fff, 400px 0 0 #fff;
    cursor: pointer;
    margin-top: 50px;
    color: white;
    padding: 15px 30px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    background-color: #1967d2;
    border: none;
    font-size: 16px;
    
    width: 190px;
    min-width: 190px;
}



@media (max-width: 500px) {

    .change-password-container .title-section .title h2 {
        font-size: 16px;
    }

    .change-password-container .fields-section .row-wrap .new-pass-section {
        margin-top: 30px;
    }

    .change-password-container .fields-section .row-wrap .new-pass-section,
    .change-password-container .fields-section .row-wrap .old-pass-section,
    .change-password-container .fields-section .row-wrap .confirm-pass-section {
        width: 100%;
    }

    .change-password-container .fields-section .field-comp-special {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .change-password-container .fields-section .show-hide-eye-50,
    .change-password-container .fields-section .show-hide-eye-100 {
        left: 77%;
    }

    .change-password-container .fields-section .show-hide-eye-100 ,
    .change-password-container .fields-section .show-hide-eye-50,
    .change-password-container .fields-section .lock-icon { 
        font-size: 18px;
        width: 34px !important;
    }

    .change-password-container .fields-section .mobile-none {
        visibility: hidden;
    }

    .change-password-container .fields-section .input-itself {
        font-size: 12px !important;
        padding-left: 30px;
    }

    .submit-btn-itself {
    
        text-shadow: 0 0 0 #fff, 400px 0 0 #fff;
        cursor: pointer;
        margin-top: 50px;
        color: white;
        padding: 15px 0;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        background-color: #1967d2;
        border: none;
        font-size: 16px;
        width: 160px;
        min-width: 160px;
    }

}












