

.navbar {
    
  background-color: #e4fde4; /*  #051826;  */
  color: rgb(30, 66, 6);      /*for the text color inside the div*/
  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: stretch; */
  padding: 0 1rem;
  width: 100%;

  height: 60px;
  position: fixed;
  z-index: 50;
}

.site-title {
    font-size: 2rem;
}

#logout-profile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  gap: 10px;

  position: absolute;
  right: 17px;
  top: 60px;
  background: white;
  width: 200px;
  height: fit-content;
  /* min-height: 50px; */
  border-radius: 2px;
  padding: 13px;
  visibility: hidden;

  box-shadow: 2px 5px 59px -11px #c0c0f9;
  z-index: 600;
}


.navbar #logout-profile .profile-row .profile-logo,
.navbar #logout-profile .logout-row .logout-logo,
.navbar #logout-profile .change-password-row .change-password-logo {
  color: #1967D2;
  font-size: 20px;
  width: 20px;
  min-width: 20px;
  margin-right: 10px;
}


.navbar #logout-profile .profile-row .profile-title,
.navbar #logout-profile .logout-row .logout-title,
.navbar #logout-profile .change-password-row .change-password-title {
  width: 140px;
}

.navbar #logout-profile .profile-row .profile-title:hover,
.navbar #logout-profile .logout-row .logout-title:hover,
.navbar #logout-profile .change-password-row .change-password-title:hover {
  color: rgb(94, 94, 255);
  font-weight: 600;
  cursor: pointer;
}

.navbar #logout-profile .profile-row,
.navbar #logout-profile .logout-row,
.navbar #logout-profile .change-password-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  /* cursor: pointer; */
  /* gap: 12px; */
  margin: 2px;
}


.navbar a {
    color: inherit;
    text-decoration: none;

    height: 100%;
    display: flex;
    align-items: center;
    /* padding: 12px; */
}

.navbar ul.laptop {
  /* visibility: visible; */
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap:3rem;
}

.navbar ul.mobile {
  display: none;
}
  
  .navbar ul.laptop li {
    color: rgb(11 24 2);
    font-size: 15px;
    font-weight: 600;
    /* font-family: cursive */
  }

  .navbar li:hover:not(li.no-bg-color) {
    /* background-color: rgb(224, 221, 221); */
    color:#1967D2;
  }

  .navbar li.active {
    /* background-color: #058193; */
    /* color: #e4fde4; */
    border-bottom: solid 3px rgb(61, 61, 61);
}





@media (max-width: 500px) {

  .navbar {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #ffffff; /*  #051826;  */
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    z-index: 50;
    
    height: 40px;
    max-height: 40px;
    min-height: 40px;
    
    border-top: solid 1px #f1f1f1;
    background: #fefefe;
    /* border-bottom: solid 1px #fefefe; */
    /* border: solid 1px; */

    /* padding: 0 1rem; */
    /* min-height: 100vh; */
    /* min-height: -webkit-fill-available;  calc() */
  }

  .img-span, .navbar .name-div, .navbar ul.laptop {
    display: none !important;
  }

  .navbar ul.mobile {
    padding: 0;
    /* margin-bottom: 5px; */
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: fixed;
    bottom: 0;
    gap:10%;
    width: 100%;
    justify-content: space-evenly;
    
    height: 40px;
    max-height: 40px;
    min-height: 40px;
    
    /* border: solid 1px; */
  }

  .navbar .hide {
    visibility: hidden;
  }

  .navbar #logout-profile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    gap: 10px;
    position: absolute;
    right: 7px;
    /* bottom: 20px; */
    top: -145px;
    background: white;
    width: 200px;
    height: fit-content;
    /* min-height: 50px; */
    border-radius: 5px;
    padding: 13px;
    visibility: hidden;
  
    box-shadow: none;
    z-index: 600;
  }

  .navbar ul.mobile li {
    color: rgb(50 57 110);
    font-size: 23px;
  }
  
}