

.right-side {
    
    display: flex;
    flex-direction: column;
    width: 100%;
}

/* example of how to write responsive css */

@media (max-width: 500px) {

    .right-side {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
    }

}