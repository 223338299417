
.ind-element {
    flex-basis: 25%;
    position: relative;
}

.element-id {
    position: absolute;
    background-color: burlywood;
    opacity: 70%;
    border-radius: 35%;
    width: 22px;
    height: 22px;
    padding: 6px;
    text-align: center;
    right: 192px;
    bottom: 10px;
}