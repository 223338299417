



.signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 10;
    background-color: #f0f6fe;

}

.signup-container .container-fluid {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;

    width: 100%;
    padding-right: var(--bs-gutter-x, .75rem);
    padding-left: var(--bs-gutter-x, .75rem);
    margin-right: auto;
    margin-left: auto;
}

.signup-container .container-fluid .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1* var(--bs-gutter-y));
    margin-right: calc(-.5* var(--bs-gutter-x));
    margin-left: calc(-.5* var(--bs-gutter-x));
}

.signup-container .row .left-side {
    background-color: #f0f6fe;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    width: 66.66666667%;
}

.signup-container .row .twm-log-reg-media {
    padding: 40px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.signup-container .row .twm-l-media {
    max-width: 1100px;
    margin: 0 auto;
}

.signup-container .row .left-side img {
    width: 100%;
    height: auto;
    max-width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none;
    vertical-align: middle;
}


/* Right side */
.signup-container .row .page-loader {
    flex: 0 0 auto;
    width: 33.33333333%;
    background: white;
}

.signup-container .row .page-loader .twm-log-reg-form-wrap {
    padding: 30px 45px;
    border-radius: 10px;
    background-color: #fff;
}

.signup-container .row .page-loader .twm-log-reg-form-wrap .twm-log-reg-logo-head {
    text-align: center;
    border-bottom: 1px solid #efefef;
    padding-bottom: 30px;
    margin-bottom: 50px;
}

.signup-container .row .page-loader .twm-log-reg-form-wrap .twm-log-reg-logo-head .twm-log-reg-head {
    text-align: center;
}

.signup-container .row .page-loader .twm-log-reg-form-wrap .twm-log-reg-head .log-reg-form-title {
    font-size: 32px;
    display: block;
    margin-bottom: 20px;
    font-weight: 600;
    color: rgb(85, 85, 85);
    text-align: center;
    font-weight: 700;
}

.signup-container .twm-tabs-style-2 .tab-content .tab-pane {
    display: block;
}

.signup-container .twm-tabs-style-2 .tab-content .tab-pane .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1* var(--bs-gutter-y));
    margin-right: calc(-.5* var(--bs-gutter-x));
    margin-left: calc(-.5* var(--bs-gutter-x));
    width: 100%;

}


.signup-container .twm-tabs-style-2 .tab-content .tab-pane .row form {
    width: 100%;
}


.signup-container .twm-tabs-style-2 .tab-content .tab-pane .row .login-fields {
    display: flex;
    flex-direction: column;
    align-items: self-start;
    justify-content: space-around;
    width: 100%;
    margin-top: 40px;
}

.signup-container .twm-tabs-style-2 .tab-content .tab-pane .row .login-fields .element {
    width: 100%;
    margin-bottom: 25px;
}

.signup-container .twm-tabs-style-2 .tab-content .tab-pane .row .login-fields .input-field:focus {
    border: solid 1px #8ab5f5;
    outline: none;
}

.signup-container .twm-tabs-style-2 .tab-content .tab-pane .row .login-fields .input-field {
    width: 100%;
    height: 55px;
    padding: 15px 20px;
    border: 1px solid #cfdbed;
    border-radius: 10px;
    box-shadow: 0 7px 15px rgba(61, 70, 83, .1);
    background-color: #fff;
    display: block;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    width: 100%;
}


.signup-container .twm-tabs-style-2 .tab-content .tab-pane .row .login-fields .show-hide-eye {    
    position: absolute;
    cursor: pointer;
    left: 81%;
    top: 0;
    width: 55px;
    height: 55px;
    z-index: 5;
    line-height: 55px;
    text-align: center;
    font-size: 24px;
    color: #1967d2;
}

.signup-container .disabled {
    background-color: #d5d5d5 !important;
    color: #9d9d9d !important;
    /* text-shadow: 0 0 0 #fff, 400px 0 0 #fff; */
    cursor: not-allowed !important; 
}

.signup-container .account-type-section .comp-title {
    margin-top: 11px;
    color: #17171d;
    font-weight: 500;
}

.signup-container div.strong-password-conditions {
    font-style: italic;
    font-size: 12px;
    color: #c1c1c1;
    width: 100%;
}


.signup-container .twm-tabs-style-2 .tab-content .tab-pane .row .login-fields .account-type-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    font-size: 18px;
}


.signup-container .twm-tabs-style-2 .tab-content .tab-pane .row .login-fields .account-type-section .every-choice {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: space-between; */
    /* gap: 10px; */
}

.signup-container .twm-tabs-style-2 .tab-content .tab-pane .row .login-fields .account-type-section input[type="radio"] {
    width: 20px;
    height: 20px;
    cursor: pointer;
}


.signup-container .twm-tabs-style-2 .tab-content .tab-pane .row .signup-login {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.signup-container .twm-tabs-style-2 .tab-content .tab-pane .row .signup-login .signup-div,
.signup-container .twm-tabs-style-2 .tab-content .tab-pane .row .signup-login .login-div {
    /* width: 34%; */
    color: white;
    height: 55px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 60px;
    font-weight: 600;
}

.signup-container .twm-tabs-style-2 .tab-content .tab-pane .row .signup-login .signup-submit {
    border: none;
    cursor: pointer; 
    /* background: blue; */
    background: #1967d2;
    color: white;
    padding: 10px 20px;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    font-weight: 600;

    width: 110px;
    min-width: 110px;
}


.signup-container .twm-tabs-style-2 .tab-content .tab-pane .row .signup-login .signup-submit {
    text-decoration: none;
}

.signup-container .signup-login.element {
    direction: rtl;
}

.signup-container .twm-tabs-style-2 .tab-content .tab-pane .row .type-label {
    cursor: pointer;
    width: 110px;
    min-width: 110px;
    max-width: 110px;
}


.signup-container .footer {
    color: #fff;
    background-color: #232323;
    width: 100%;
}

.signup-container .hear-about-us select {
    padding-left: 17px;
    border: solid 1px #b9b9f7;
    height: 59px;
    font-size: 16px;
    color: #606572;
    width: 100%;
    border-radius: 11px;
    margin-top: 15px;
}




@media (max-width: 500px) {

    .signup-container .container-fluid {
        margin-bottom: 10px;
    }

    .signup-container .row .left-side, .signup-container .row .page-loader {
        width: 100%;
        border-radius: 5px;
    }

    .signup-container .row .twm-l-media {
        width: 57%;
    }

    .signup-container .row .twm-log-reg-media {
        padding: 0 0 14px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .signup-container .row .page-loader .twm-log-reg-form-wrap {
        /* padding: 18px 9px 0 35px; */
        padding: 18px 9px 25px 35px;
    }

    .signup-container .row .page-loader .twm-log-reg-form-wrap .twm-log-reg-logo-head {
        margin-bottom: 15px;
        width: 91%;
        display: none;
    }

    .signup-container .twm-tabs-style-2 .tab-content .tab-pane .row .login-fields {
        margin-top: 10px;
    }

    .signup-container .twm-tabs-style-2 .tab-content .tab-pane .row .login-fields .show-hide-eye {
        font-size: 18px;
        width: 34px !important;
    }

    .signup-container .twm-tabs-style-2 .tab-content .tab-pane .row .signup-login {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        margin-bottom: 45px;
        width: 100%;
        /* border: solid 1px black; */
        height: 40px;
    }

    .signup-container .twm-tabs-style-2 .tab-content .tab-pane .row .signup-login .signup-div {
       
        color: white;
        display: flex;
        justify-content: center;
        font-weight: 600;
        /* border: solid 1px red; */
        
        width: 100%;
        min-height: 40px !important;
        height: 40px !important;
        max-height: 40px !important;
    }

    .signup-container .twm-tabs-style-2 .tab-content .tab-pane .row .login-fields .input-field {
        width: 100%;
        height: 55px;
        padding: 15px 20px;
        border: 1px solid #cfdbed;
        border-radius: 10px;
        box-shadow: 0 7px 15px rgba(61, 70, 83, .1);
        background-color: #fff;
        display: block;
        width: 100%;
        font-size: 14px !important;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        width: 100%;
    }

    .signup-container .twm-tabs-style-2 .tab-content .tab-pane .row .signup-login .signup-submit {
        border: none;
        cursor: pointer; 
        /* background: blue; */
        background: #1967d2;
        color: white;
        padding: 10px 20px;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        width: 100%;
        height: 40px !important;
    }

    .signup-container .footer {
        color: #fff;
        background-color: #232323;
        width: 100%;
        margin-bottom: 40px;
    }
}










