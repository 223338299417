

.sent-successfully .loggedin-block {
    margin: 65px;
    border: solid 1px #ffeeb5;
    border-radius: 5px;
    background: #FFF3CD;
    /* width: 80%; */
    height: 61px;
    margin-top: 77px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 20px;
}

.sent-successfully .loggedin-block .back {
    border: none;
    color: blue;
    margin-left: 15px;
    cursor: pointer;
    background: transparent;
}




@media (max-width: 500px) {


    .sent-successfully .loggedin-block {
        height: 100px;
        margin-top: 77px 10px 65px 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 20px;
        justify-content: space-evenly;
    }

    .sent-successfully .loggedin-block .back {
        margin-left: 0 !important;
        padding: 0 !important;
    }

}








