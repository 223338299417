


.tree-container {
    margin-left: auto;
    margin-right: auto;
    width: 450px;
    margin-top: 100px;
}

.tree-container .parents {  
    margin-bottom: 10px;
    min-width: 400px;
}

.tree-container .children {
    margin-left: 30px;
   
    min-width: 400px;

    /* opacity: 1;
    visibility: visible;
    transition: opacity 0.5s, visibility 0.5s; */

/*     
    overflow: auto;
    height: auto; */
    /* display: block; */
    opacity: 1;
    transition: height 0.5s, opacity 0.5s ease;
}

.tree-container .children.hide {
    /* visibility: hidden;
    opacity: 0; */
    display: none;
    /* height: 0;
    opacity: 0; */
}

.tree-container span {
    margin-right: 5px;
}

.tree-container .child-title-block {
    display: inline;
    margin: 13px 0;
 }
 
 .tree-container .child-title-block:hover .edit-child-title {
    visibility: visible;
    /* display: block; */
 }

 .tree-container .edit-child-title {
    position: absolute; 
    visibility: hidden;
    cursor: pointer;
}

.tree-container div.child-title-block .orange-back {
    background-color: rgb(237, 154, 36);
    border-radius: 20px;
    color: aliceblue;
}

.tree-container .child-title-not-editable {
    padding: 5px 10px;
}

/* .tree-container .child-title-block:hover > span {
    background-color: rgb(248, 215, 215, 0);
} */

.tree-container .done {
    width: 110px !important;
}
