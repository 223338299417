
.website-icon-container {
    /* --fa-rotate-angle: 45deg; */
    /* transform: rotate(318deg);
    z-index: 5; */
    margin-top: 100px;
    margin-right: auto;
    margin-left: auto;
    background: #e4fde4;
    height:500px;
    width: 500px;
}

.website-icon-container .three-lines {
    position: absolute;
   
    z-index: 83;
    top: 200px;
    left: 500px;
    height: 20px;
    width: 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.website-icon-container .line1 {
    width: 44px;
    height:3px;
    border-radius: 3px;
    border: solid 3px white;
}

.website-icon-container .line2 {
    width: 29px;
    height:3px;
    border-radius: 3px;
    border: solid 3px white;
}

.website-icon-container .line3 {
    width: 15px;
    height:3px;
    border-radius: 3px;
    border: solid 3px white;
}

.website-icon-container .circle-bg {
    border-radius: 50%;
    background: linear-gradient(45deg, #1B74CE, #448cd5, #edeff1); /* #1B74CE; */
    position: absolute;
    z-index: 61;
    width: 150px;
    height: 150px;
    margin: 60px 0 0 60px;
}

.website-icon-container .envelop-bg {
    background: #165EA5;
    transform: rotate(310deg);
    z-index: 65;
    width:20px;
    height: 20px;
    position: absolute;
}

.website-icon-container .envelop-icon {
    color: white;
    font-size: 80px;
    position: absolute;
    top: 195px;
    left: 519px;
    z-index: 70;
    transform: rotate(315deg);

}

.website-icon-container .envelop-icon_front {
    color: white;
    font-size: 80px;
    position: absolute;
    top: 195px;
    left: 519px;
    z-index: 70;
    transform: rotate(300deg);

}
