

.apply-job-container {   

    padding: 0 100px;
    background: #f7f9ff;

    display: flex;
    flex-direction: column;
    align-items: normal;
    justify-content: space-around;
    gap: 3rem;
}

.apply-job-container .big-picture {
    margin-top: 50px;
}

.apply-job-container .big-pic {
    width:100%;
    height:400px;
    border-radius: 12px;
}

.apply-job-container .line1 {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: -83px;
    margin-left: 28px;
}

.apply-job-container .line1 .company-pic {
    width: 100px;
    height: 100px;
    border-radius: 7px;
}


.apply-job-container .line1 .apply-btn {
    width: 106px;
    background: rgb(77 116 245);
    border: solid 0;
    border-radius: 7px;
    padding: 12px;
    font-size: 16px;
    margin-left: auto;
    margin-right: auto;
    direction: rtl;
    color: whitesmoke;
    font-weight: 600;
    cursor: pointer;
}

.apply-job-container .applied-btn {
    width: 106px;
    background: rgb(255, 255, 255);
    border: solid 1px rgb(77 116 245);;
    border-radius: 7px;
    padding: 12px;
    font-size: 16px;
    margin-left: auto;
    margin-right: auto;
    direction: rtl;
    color: rgb(77 116 245);
    font-weight: 600;
    /* cursor: pointer; */
}

.apply-job-container .upload-line {
    /* border: solid 1px; */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

/* .apply-job-container .upload-line .apply-col2 {

} */

/* .apply-job-container .upload-line .apply-col2 .apply-btn.disabled {
    background: rgb(202, 202, 202);
    cursor: not-allowed;
} */

.apply-job-container button.disabled {
    background-color: #d5d5d5 !important;
    color: #9d9d9d !important;
    /* text-shadow: 0 0 0 #fff, 400px 0 0 #fff; */
    cursor: not-allowed !important;
}


.apply-job-container .upload-line .apply-col2 .apply-btn,
.apply-job-container .apply-btn-bottom .apply-btn {
    width: 106px;
    /* background: rgb(77 116 245); */
    background: #2478eb;
    border: solid 0;
    border-radius: 5px;
    padding: 12px;
    font-size: 16px;
    margin-left: auto;
    margin-right: auto;
    direction: rtl;
    color: whitesmoke;
    font-weight: 600;
    cursor: pointer;
}

.apply-job-container .job-summary .title-line1 {
    color: #183068;
    font-size: 28px;
    font-weight: 700;
    max-width: 1100px;
    margin-bottom: 12px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.apply-job-container .job-summary .title-line1 .div-back-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height:37px;
}

.apply-job-container .job-summary .title-line1 .div-back-button-mobile {
    display: none;
}

.apply-job-container .job-summary .title-line1 .div-back-button .back-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height:100%;
    border: none;
    border-radius: 5px;
    padding: 0 17px;
    background: #2a86ff;
    color: white;
    cursor: pointer;
    
}

.apply-job-container .job-summary .location-line2 {
    color: gray;
    font-size: 17px;
}

.apply-job-container .job-details {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    background: #f0f0ff;
    gap: 3rem;
    padding: 24px;
}

.apply-job-container .job-details .col1 {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: self-start;
    justify-content: space-between;
    gap: 24px;
    background: white;
    border-radius: 8px;
    padding: 32px;
}

.apply-job-container .job-details .col2 {
    width: 70%;
}

.apply-job-container .job-details .col2 .job-description-section {
    margin-bottom: 65px;
}

.apply-job-container .single-info-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}


.apply-job-container .job-details .col1 .job-information-text {
    font-weight: 600;
    font-size: 23px;
    border-bottom: solid 3px #1f1fff;
}


.apply-job-container .single-info-container .icon {
    color: rgb(46, 137, 255);
    font-size: 30px;
    width: 51px;
    height: 51px;
    /* border: solid 1px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #d8efff;
    border-radius: 50%;
    margin-right: 18px;
    min-width: 51px;
}

.apply-job-container .single-info-container .textes {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
}


.apply-job-container .single-info-container .textes .title {
    color: rgb(46, 137, 255);
    font-size: 17px;
    font-weight: 600;
}

.apply-job-container .single-info-container .textes .value {
    font-size: 14px;
    /* border: solid 1px; */
    width: 100%;
    max-width: 200px;
    font-weight: 500;
    color: #545454;
}

.apply-job-container .col2 .section-title {
    background: #2a86ff;
    color: white;
    height: 45px;
    /* margin: 30px 20px; */
    text-align: center;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 21px;
    width:100%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 23px;
}

.apply-job-container .col2 .details {
    margin-bottom: 40px;
}

.apply-job-container .col2 .details * {
    background: #f0f0ff !important;
}

.apply-job-container .apply-btn-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
}


.apply-job-container .apply-btn-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
}


.apply-job-container .hor-line {
    margin-top: 58px;
}



@media (max-width: 500px) {

    .apply-job-container {
        gap: 15px;
    }

    .apply-job-container .job-details .col1 {
        display: none;
    }

    .apply-job-container {
        /* padding: 0 !important; */
        padding: 0 0 15px 0 !important;
    }

    
    .apply-job-container .big-picture {
        margin-top: 20px;
        display: none !important;
    }

    .apply-job-container .big-pic {
        height: 160px;
    }

    .apply-job-container .line1 {
        display: none;
    }

    .apply-job-container .job-summary {
        padding: 10px 0 0 24px;
    }


    .apply-job-container .job-summary .title-line1 {
        color: #183068;
        font-size: 28px;
        font-weight: 700;
        max-width: 1100px;
        margin-bottom: 12px;
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        justify-content: space-between;
    }

    .apply-job-container .job-summary .title-line1 .div-back-button {
        display: none;
    }
    
    .apply-job-container .job-summary .title-line1 .div-back-button-mobile {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height:37px;
        margin-bottom: 24px;
        color:  rgb(59 59 59);
    }






    .apply-job-container .job-details .col2 {
        width: 100% !important;
    }

    .apply-job-container .upload-line {
        /* border: solid 1px; */
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        margin-left: 24px;
        margin-right: 24px;
        margin-top: 45px;

    }

    .apply-job-container .upload-line .apply-col2 {
        margin-top: 20px !important;
        width: 100%;
        height: 40px;
    }

    .apply-job-container .upload-line .apply-col2 .apply-btn {
        width: 100%;
        height: 100%;
    }

    .apply-job-container .hor-line {
        margin-top: 0 !important;
    }

    .file-uploader-container {
        flex-direction: column !important;
        width: 100%;
    }

    .file-uploader-container .upload-plus-accept {
        width: 100% !important;
        /* border: solid 1px; */
    }

    .file-uploader-container .selected-files {
        margin-left: 0 !important;
        width: 100%;
        /* border: solid 1px; */
    }

    .file-uploader-container .uploader-label {
        width: 100% !important;
        max-width: 100% !important;
        height: 40px !important;
    }

}








/* 

.apply-job-container div.div-header {
    background: #6D7C90;
    color: rgb(66, 58, 58);
    height: 45px;
    margin: 30px 20px;
    text-align: center;
    border-bottom: solid 1px black;
    border-top: solid 1px black;
    border-radius: 3px;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 21px;
    width:85%;
    margin-right: auto;
    margin-left: auto;
}

.apply-job-container span.unknown-val {
    color : #c1c1c1;
    font-style: italic;
} */