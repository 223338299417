.side-bar-container {
    /* if we navigate to a page containing a scrollbar on the right, the sidebar width changes */
    

    /* initial code was = 230px  */
    /* width: 230px;
    min-width: 230px; */

    width: 0;
    /* display: none; */
}



@media (max-width: 500px) {

    .side-bar-container {
        /* if we navigate to a page containing a scrollbar on the right, the sidebar width changes */

        /* initial code was = 230px  */
        /* width: 230px;
        min-width: 230px;
        width: 10%;
        background: #2d5275;
        /* height: 100vh;   commented because vh means 100% of the "viewed screen"   
        transition: width 1s;
        color:whitesmoke;*/

        background-color: #e4fde4; /*  #051826;  */
        color: rgb(30, 66, 6);      /*for the text color inside the div*/
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0 1rem;
        z-index: 50;
        min-height: 100vh;
        width: 20%;
    }

    .side-bar-container .title {
        padding: 10px 0;
        align-items: center;
    }

    .hidden {
        width:0px;
    }

    .side-bar-container .options {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    .side-bar-container .options a {
        color: whitesmoke;
        text-decoration: none;
    }

    .side-bar-container .options a.active {
        color: #d3e014;
    }

    .side-bar-container .options a:hover {
        color: #00a8ff;
        background-color: rgb(230, 233, 236);
    }

}