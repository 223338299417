


.contactus-container .blue-div {
    width: 100%;
    height: 300px;
    color: white;
    position: relative;
    background: #0000ff6b;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    font-weight: 500;
    /* margin-top: 60px; */
}

.contactus-container .home-contactus {
    background: #1967D2;
    border-radius: 10px;
    color: white;
    position: relative;
    left: 43%;
    height: 30px;
    width: 180px;
    top: -26px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 191px;
    height: 51px;
    font-weight: 600;
    font-size: 16px;
}

.contactus-container .contactus-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8rem;
    margin-top: 4%;
}

/* .contactus-container form {
    width: 100%;
} */

.contactus-container .feel-free {
    color: #8d8d8d;
}

.contactus-container .fields {
    gap: 1px;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.contactus-container .send-message {
    font-size: 46px;
    font-weight: 700;
}

.contactus-container .username-email {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 21px;
    margin-top: 35px;
}

.contactus-container .message {
    margin-top: 21px;
    width: 100%;
}

.contactus-container .submitbutton {
    margin-top: 26px;
    width: 535px;
}

.contactus-container .submit-input {
    cursor: pointer;
    border: 0px solid;
    background: #1967D2;
    color: white;
    width: 156px !important;
    font-size: 16px;
    padding: 0 0 0 0 !important;
    font-weight: 600;
    margin-top: 20px;
}


.contactus-container .contactus-content .fields .field-itself:focus {
    border: solid 1px #8ab5f5;
    outline: none;
}


.contactus-container .field input,
.contactus-container .field button {
    height:40px;
    width: 258px;
    height: 50px;
    padding: 10px 0 10px 20px;
}


.contactus-container .field input ,
.contactus-container .field button ,
.contactus-container .field textarea {
    border: solid 1px rgb(209, 209, 209);
    border-radius: 10px;
    font-size: 15px;
}

.contactus-container .field .disabled {
    background-color: #d5d5d5;
    color: #9d9d9d;
    cursor: not-allowed;
}

.contactus-container textarea {
    resize: none;
    width: 538px;
    height: 200px;
    padding: 18px 21px 10px 20px;
}


.contactus-container .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    gap: 2rem;
}
 
.contactus-container .addresses {
    background: white;
    gap: 3rem;
    display: flex;
    flex-direction: column;
    /* border: solid 1px black; */
    padding: 40px;
    box-shadow: 5px 5px 40px -12px rgb(171 205 200);
    border-radius: 15px;
    z-index: 3;
    height: 422px;
    justify-content: space-evenly;
}

.contactus-container .textes{
    gap: 1rem;
    display: flex;
    flex-direction: column;
}


.contactus-container .small {
    font-size: 15px;
    width: 290px;
}

.contactus-container .big {
    font-size: 20px;
    font-weight: 600;
}

.contactus-container .logo {
    font-size: 30px;
    width: 70px;
    height: 70px;
    /* padding: 0; */
    background: #eaf3f5;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1967D2;
}

.contactus-container .behind-addresses {
    color: rgb(221, 234, 255);
    width: 450px;
    height: 589px;
    position: absolute;
    /* border: solid 1px black; */
    left: 60%;
    top: 380px;
    z-index: 2;
    background: #f2faff;
}

.contactus-container .triangle {
    color: #D0D5D9;
    font-size: 176px;
    transform: rotate(315deg);
    top: 823px;
    right: 38%;
    position: absolute;
    z-index: 0;
}




@media (max-width: 500px) {

    .every-page-container {
        width: 100%;
        position: relative;
    }

    .contactus-container .fields {
        gap: 1px;
        display: flex;
        flex-direction: column;
        align-items: start;
        padding: 0 10px;
    }

    .contactus-container .blue-div,
    .contactus-container .contactus-img {
        height: 14% !important;
        font-size: 25px;
        position: absolute;
    }

    .contactus-container .send-message {
        font-size: 25px;
        font-weight: 700;
        margin-bottom: 20px;
    }
    
    .every-page-container .contactus-img {
        width: 100%;
        height: 20%;
        position: absolute;
    }

    .contactus-container .home-contactus {
        left: 0;
        margin-right: auto;
        margin-left: auto;
        margin-top: 146px;
    }

    .contactus-container .addresses, .contactus-container .behind-addresses {
        display: none;
    }

    .contactus-container .field input, 
    .contactus-container .field button {
        width: 100%;
    }

    .contactus-container .contactus-content .fields .field-itself {
        font-size: 12px;
    }

    .contactus-container .username-email {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 21px;
        margin-top: 35px;
    }

    .contactus-container textarea,
    .contactus-container .username,
    .contactus-container .email,
    .contactus-container .username-email {
        width: 100%;
    }

    .contactus-container .hor-line {
        margin-top: 55px !important;
    }

    .contactus-container .submitbutton {
        margin-top: 26px;
        width: 100%;
    }

}



/* 

old layout
.contactus-container {
    display: flex;
    height: 100%;
    margin: 0 182px;
    padding: 0 100px;
}

.contactus-container div.bg-image{

    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    padding: 30px 0 0 0;
}

.contactus-container div.page-title {
    font-size: 16px;
    color: rgb(187, 148, 123);
}

.contactus-container div.page-content {
    display: flex;
    flex-direction: row;
    align-items: center;
   
    gap: 22rem;
}

.contactus-container div.my-contact-info {
  
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.contactus-container div.my-contact-info div.title {
    color: whitesmoke;
}

.contactus-container div.contact-fields input {
    height: 35px;
}
.contactus-container div.contact-fields input,
.contactus-container div.contact-fields textarea {
    background-color: rgb(68, 68, 70, 0.5);
    color: white;
    padding: 10px;
    width: 300px;
}

.contactus-container div.contact-fields textarea {
    resize: none;
    height: 140px;
}

.contactus-container input.clean,
.contactus-container textarea.clean {
    border: solid 0;
}

.contactus-container input.error,
.contactus-container textarea.error {
    border: solid 1px red; 
}

.contactus-container .action {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.contactus-container .disable-button {
    cursor: progress !important;
    color: rgb(146, 146, 146) !important;
} */
