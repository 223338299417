

.dialog-container {
    width: 100%;
    height: 100%;
    /* background: rgb(131 130 130 / 63%); */
    position: fixed;
    top:0;
    z-index: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.dialog-container .dialog-box {
    /* background: rgb(250, 250, 250); */
    background: white;
    border-radius: 3px;
    width: 50%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* border: solid 1px gray; */
    padding: 25px;
    gap: 20px;
}

.dialog-container .dialog-box .first-line {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.dialog-container .dialog-box .second-line {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 10px;
    width: 100%;
}

.dialog-container .dialog-box .second-line .phone-field {
    padding: 7px;
    font-size: 14px;
    width: 100px;
}

.dialog-container .dialog-box .first-line .close-btn {
    cursor: pointer;
    border: solid 1px rgb(216, 215, 215);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.dialog-container .dialog-box .title {
    /* border: solid 1px gray; */
    /* height: 50px;
    width: 200px; */
    font-size: 26px;
}

.dialog-container .dialog-box .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.dialog-container .dialog-box .buttons .btn {
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    width: 100px;
    height: 35px;
    font-size: 20px;
    cursor: pointer;
    /* padding: 5px 10px; */
} 

.dialog-container .dialog-box .buttons .primary {
    background: rgb(99, 164, 248);
}

.dialog-container .dialog-box .buttons .danger {
    background: red;
}

.dialog-container .dialog-box .buttons .success {
    background: rgb(89, 247, 58);
}