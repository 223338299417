

.company-profile-container .page-container {
    background: rgb(236, 242, 248);
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.company-profile-container .page-container .title-section {
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    position: relative;
    padding: 15px 30px;
    margin-bottom: 30px;
    box-shadow: 0 20px 30px rgba(0, 51, 90, .05);
    border-radius: 10px;
}

.company-profile-container .page-container .title-section .title h2{
    font-size: 25px;
    color: #17171d;
    font-weight: 600;
    margin-bottom: 11px;
    font-family: Rubik, sans-serif, arial;
}

.company-profile-container .page-container .title-section .path {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 11px;
}

.company-profile-container .page-container .title-section .path .circle {
    border-radius: 50%;
    width: 5px;
    height: 5px;
    background: #17171d;
    margin-top: 4px;
}

.company-profile-container .page-container .title-section .path .change-password {
    color: rgb(88, 88, 255);
}

.company-profile-container .fields-section {
    padding: 30px 30px 0 30px;
    background: white;
    border-radius: 10px;
}

/* "Logo + Html-file element" section */
.company-profile-container .fields-section .row-wrap .logo-file-section {
    flex: 0 0 auto;
    width: 80%;
    margin-bottom: 50px;
    height: 155px;
    min-height: 155px;
}

.company-profile-container .fields-section .row-wrap .logo-file-section .file-uploader-container {
    position: absolute !important;
    left: 150px  !important;
}

.company-profile-container .fields-section .row-wrap {
    display: flex;
    flex-wrap: wrap;
}


.company-profile-container .fields-section .row-wrap .company-name-section ,
.company-profile-container .fields-section .row-wrap .company-confid-section ,
.company-profile-container .fields-section .row-wrap .company-email-section {
    flex: 0 0 auto;
    width: 33.33%;
}

.company-profile-container .fields-section .row-wrap .company-location-section {
    flex: 0 0 auto;
    width: 100%;
}

/* .company-profile-container .fields-section .row-wrap .company-emailsubject-section, */
.company-profile-container .fields-section .row-wrap .company-emailbody-section ,
.company-profile-container .fields-section .row-wrap .company-company-profile-section {
    flex: 0 0 auto;
    width: 50%;
}

.company-profile-container .btn-save-profile {
    flex: 0 0 auto;
    width: 20%;
    direction: rtl;
}

.company-profile-container .fields-section .disabled {
    background-color: #d5d5d5 !important;
    color: #9d9d9d !important;
    /* text-shadow: 0 0 0 #fff, 400px 0 0 #fff; */
    cursor: not-allowed !important;
}

.company-profile-container .fields-section .row-wrap .field-comp {
    margin-bottom: 30px;
    /* padding-left: 20px; */
    /* padding-right: 40px; */
}

.company-profile-container .fields-section .row-wrap .field-comp-pr {
    padding-right: 25px;
}

.company-profile-container .fields-section .row-wrap .field-comp-pl {
    padding-left: 25px;
}

.company-profile-container .fields-section .row-wrap .comp-title {
    margin-bottom: 11px;
    color: #17171d;
    font-weight: 500;
}

.company-profile-container .fields-section .row-wrap .diff-margin {
    margin-bottom: 22px;
    color: #17171d;
    font-weight: 500;
}

.company-profile-container .fields-section .row-wrap .input-itself {
    padding: 15px 20px;
    height: 55px;
    /* padding: 15px 20px; */
    border: solid 1px #cfdbed;
    border-radius: 10px;
    box-shadow: 0 7px 15px rgba(61, 70, 83, .1);
    background-color: #fff;
    display: block;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
}

.company-profile-container .fields-section .row-wrap .input-itself:focus {
    border: solid 1px #8ab5f5;
    outline: none;
}


/* ReactQuill control css, to set: width, height, max height, ... */

.company-profile-container .fields-section .row-wrap .ql-container {
    min-height: 250px !important;
    height:250px !important;
    width: 100% !important;
}

.company-profile-container .fields-section .row-wrap .ql-toolbar.ql-snow {
    width: 100%;
}

.company-profile-container .fields-section .row-wrap .ql-editor {
    max-height: 250px !important;
}

.company-profile-container #div-save-profile {
    height: 50px;
    min-height: 50px;
    /* margin-left: auto; */
    /* position: absolute; */
}

.company-profile-container #div-save-profile_2 {
    height: 50px;
    min-height: 50px;
    margin-left: auto;
}

.company-profile-container .profile-btn-itself {
    
    text-shadow: 0 0 0 #fff, 400px 0 0 #fff;
    cursor: pointer;
    /* margin-top: 50px; */
    color: white;
    padding: 15px 30px;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    background-color: #2478eb;
    border: none;
    font-size: 16px;

    width: 110px;
    min-width: 110px;
}




/* my mobile =  Resolution, width: 360, height: 512 pixels      
from https://www.google.com/search?q=samsung+galaxy+a02+core+dimensions+in+px&sca_esv=5e5568ccfe61287d&sca_upv=1&sxsrf=ADLYWII6uHR2k1JHEeVcpRUCCIPG4qdzDA:1726508267914&tbas=0&source=lnt&sa=X&ved=2ahUKEwjI5orAgMiIAxWwRKQEHfqqJpIQpwV6BAgDEAY&biw=1366&bih=607&dpr=1
*/

@media (max-width: 500px) {


    .company-profile-container .page-container .title-section .title h2 {
        font-size: 16px;
    }

    .company-profile-container .fields-section .row-wrap .logo-file-section .file-uploader-container {
        position: relative !important;
        left: 0 !important;
    }

    /* "Logo + Html-file element" section */
    .company-profile-container .fields-section .row-wrap .logo-file-section {
        flex: 0 0 auto;
        width: 80%;
        margin-bottom: 0;
        margin-bottom: 30px;
        height: fit-content !important;
        min-height: 157px;
    }

    .company-profile-container #div-save-profile {
        height: 40px;
        min-height: 40px;
        max-height: 40px;
    }

    .company-profile-container .profile-btn-itself {
    
        text-shadow: 0 0 0 #fff, 400px 0 0 #fff;
        cursor: pointer;
        /* margin-top: 50px; */
        color: white;
        padding: 0;
        border-radius: 5px;
        position: relative;
        overflow: hidden;
        background-color: #2478eb;
        border: none;
        font-size: 16px;
        width: 100%;
        min-width: 100%;
        max-width: 100%;

        height: 40px;
        max-height: 40px;
        min-height: 40px;
    }

    #empty-company-logo {
        display: none ;
    }

    .company-profile-container .fields-section .row-wrap .logo-file-section,
    .company-profile-container .btn-save-profile,
    .company-profile-container .fields-section .row-wrap .company-name-section,
    .company-profile-container .fields-section .row-wrap .company-email-section,
    .company-profile-container .fields-section .row-wrap .company-emailbody-section,
    .company-profile-container .fields-section .row-wrap .company-company-profile-section,
    .company-profile-container .fields-section .row-wrap .company-confid-section {
        width: 100% !important;
    }
    
    .company-profile-container .fields-section .row-wrap .ql-container {
        min-height: 325px !important;
        height:250px !important;
        width: 100% !important;
    }

    .company-profile-container .fields-section .row-wrap .field-comp-pr,
    .company-profile-container .fields-section .row-wrap .field-comp-pl {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .company-profile-container .fields-section .row-wrap .input-itself {
        padding: 15px 20px;
        height: 55px;
        /* padding: 15px 20px; */
        border: solid 1px #cfdbed;
        border-radius: 10px;
        box-shadow: 0 7px 15px rgba(61, 70, 83, .1);
        background-color: #fff;
        display: block;
        width: 100%;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
    }

    .company-profile-container .fields-section .row-wrap .input-itself::placeholder {
        
        font-size: 12px;
        white-space:pre-line;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

}





